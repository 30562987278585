/**
 * Processing Button State
 * @return object
 * @param button
 * @param POSTed
 */
export function buttonSend(button, POSTed = false) {
    if (!(button instanceof HTMLButtonElement) && !(button instanceof HTMLAnchorElement)) return;

    if (!POSTed) {
        button.disabled = true;
        button.style.cursor = 'not-allowed';
    }

    let buttonInnerWidth = 0;
    if (!button.dataset.buttonInnerwidth) {
        buttonInnerWidth = parseInt(window.getComputedStyle(button).width);
        button.dataset.buttonInnerwidth = buttonInnerWidth.toString();
    } else {
        buttonInnerWidth = parseInt(button.dataset.buttonInnerwidth);
    }

    const buttonHeight = parseInt(window.getComputedStyle(button).height);
    button.dataset.html = button.innerHTML;
    button.innerHTML = "<i class='fas fa-circle-notch fa-spin'></i>";
    button.style.minWidth = buttonInnerWidth + 'px';
    button.style.height = buttonHeight + 'px';

    const spinnerElement = button.querySelector('i');
    const spinnerHeight = spinnerElement.clientHeight;
    spinnerElement.style.width = spinnerHeight + 'px';
}

/**
 * Stop Processing Button State
 * @return object
 * @param button
 */
export function buttonReturn(button) {
    if (!(button instanceof HTMLButtonElement) && !(button instanceof HTMLAnchorElement)) return;

    const buttonHTML = button.dataset.html;
    button.disabled = false;
    button.style.cursor = 'pointer';
    button.innerHTML = buttonHTML;
}


/**
 * Stop Processing Button State with Success
 * @return object
 * @param button
 */
export function buttonReturnSuccess(button) {
    if (!(button instanceof HTMLButtonElement)) return;

    const buttonWidth = button.offsetWidth;
    const buttonHeight = button.offsetHeight;
    const fontSize = window.getComputedStyle(button).fontSize;

    button.innerHTML = "<i class='fas fa-check' style='padding: 0'></i>";
    button.style.width = `${buttonWidth}px`;
    button.style.height = `${buttonHeight}px`;
    button.style.fontSize = fontSize;
    button.classList.add('pulsate');

    setTimeout(() => {
        const buttonHtml = button.dataset.html;

        button.classList.remove('pulsate');
        button.disabled = false;
        button.style.cursor = 'pointer';
        button.style.fontSize = fontSize;

        button.innerHTML = buttonHtml;
        button.style.display = 'block'; // fadeIn equivalent

    }, 1500);
}
