module.exports = [
    {
        name: 'Diners',
        regex: /^3(?:0[0-59]{1}|[689])[0-9]{0,}$/,
        securityCodeLength: 3,
        accepted: false
    },
    {
        name: 'Discover',
        regex: /^(6011|65|64[4-9]|62212[6-9]|6221[3-9]|622[2-8]|6229[01]|62292[0-5])[0-9]{0,}$/,
        securityCodeLength: 4,
        accepted: true
    },
    {
        name: 'Amex',
        regex: /^3[47][0-9]{0,}$$/,
        securityCodeLength: 4,
        accepted: true
    },
    {
        name: 'Mastercard',
        regex: /^(5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[01]|2720)[0-9]{0,}$/,
        securityCodeLength: 3,
        accepted: true
    },
    {
        name: 'Maestro',
        regex: /^(5018|5020|5038|5893|6304|6759|6761|6762|6763)[0-9]{8,15}$/,
        securityCodeLength: 3,
        accepted: false
    },
    {
        name: 'Visa',
        regex: /^4[0-9]{0,}$/,
        securityCodeLength: 3,
        accepted: true
    },
    {
        name: 'JCB',
        regex: /^(?:2131|1800|35)[0-9]{0,}$/,
        securityCodeLength: 3,
        accepted: false
    },
];