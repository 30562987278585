const Validator = require('../sharable/Validator');

//SSO ClientID and RedirectURI
const APPLE_CLIENT_ID = (!!document.querySelector('[name=\'apple_oauth_client_id\']')) ? document.querySelector('[name=\'apple_oauth_client_id\']').value : null;
const GOOGLE_CLIENT_ID = (!!document.querySelector('[name=\'google_oauth_client_id\']')) ? document.querySelector('[name=\'google_oauth_client_id\']').value : null;
const APPLE_REDIRECT_URI = (!!document.querySelector('[name=\'apple_redirect_uri\']')) ? document.querySelector('[name=\'apple_redirect_uri\']').value : null;

/**
 * LoginFormAlertError Module
 * @return {HTMLDivElement}
 */
class LoginFormAlertError {
    constructor() {

    }

    buildAlert() {
        // Build Alert Components
        const alertDiv = this.generateAlertContainer();
        const alertCloseButton = this.generateAlertCloseButton();
        const alertIcon = this.generateAlertIcon();
        const alertBody = this.generateAlertBody();

        alertCloseButton.addEventListener('click', function() {
            alertDiv.remove();
        });

        // Construct Alert
        alertDiv.appendChild(alertCloseButton);
        alertDiv.appendChild(alertIcon);
        alertDiv.appendChild(alertBody);

        // Sendback Constructed Element
        return alertDiv;
    }

    /**
     * Build Alert Div
     * @return {HTMLDivElement}
     */
    generateAlertContainer() {
        const alertDiv = document.createElement('div');
        alertDiv.id = 'login-form-alert';
        alertDiv.setAttribute('role', 'alert');
        alertDiv.className = 'alert alert-danger alert-dismissible';
        return alertDiv;
    }

    /**
     * Build Alert Close Button
     * @return {HTMLButtonElement}
     */
    generateAlertCloseButton() {
        const alertCloseButton = document.createElement('button');
        alertCloseButton.className = 'close';
        alertCloseButton.setAttribute('type', 'button');
        alertCloseButton.setAttribute('data-bs-dismiss', 'alert');

        const alertCloseButtonIcon = document.createElement('i');
        alertCloseButtonIcon.className = 'far fa-times';

        const alertCloseButtonScreenReaderText = document.createElement('span');
        alertCloseButtonScreenReaderText.className = 'sr-only';
        alertCloseButtonScreenReaderText.innerText = 'Close';

        alertCloseButton.appendChild(alertCloseButtonIcon);
        alertCloseButton.appendChild(alertCloseButtonScreenReaderText);

        return alertCloseButton;
    }

    /**
     * Build Alert Icon
     * @return {HTMLDivElement}
     */
    generateAlertIcon() {
        const alertIconBlock = document.createElement('div');
        alertIconBlock.setAttribute('aria-hidden', true);
        alertIconBlock.className = 'alert-icon-block sr-hidden';

        const alertIcon = document.createElement('i');
        alertIcon.className = 'fas fa-exclamation-circle';

        alertIconBlock.appendChild(alertIcon);

        return alertIconBlock;
    }

    generateAlertBody() {
        const alertBodyBlock = document.createElement('div');
        alertBodyBlock.className = 'alert-body-block';

        // Title
        const alertTitleDiv = document.createElement('div');
        alertTitleDiv.className = 'alert-body-title';

        const alertTitleTextElement = document.createElement('strong');
        alertTitleTextElement.id = 'login-form-alert-title';

        alertTitleDiv.appendChild(alertTitleTextElement);
        alertBodyBlock.appendChild(alertTitleDiv);

        // Content
        const alertContentDiv = document.createElement('div');
        alertContentDiv.className = 'alert-body-content';

        const alertTextContentElement = document.createElement('p');
        alertTextContentElement.id = 'login-form-alert-content';
        alertContentDiv.appendChild(alertTextContentElement);
        alertBodyBlock.appendChild(alertContentDiv);

        return alertBodyBlock;
    }
}

/**
 * TimezoneDetector Module
 */
const TimezoneDetector = {
    /**
     * Detect Default Timezone
     * @param timezones
     * @return {null|number}
     */
    detect: function(timezones = []) {
        const browser = {
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            offset: -new Date().getTimezoneOffset()
        };

        // If both offset name and offset value matches browser output.
        for (let i = 0; i < timezones.length; i += 1) {
            if (
                browser.timezone &&
                timezones[i].name.indexOf(browser.timezone) !== -1 &&
                browser.offset === timezones[i].offset
            ) {
                return timezones[i].id;
            }
        }

        // If offset value and timezone region matches.
        for (let i = 0; i < timezones.length; i += 1) {
            if (
                browser.timezone &&
                browser.offset === timezones[i].offset &&
                timezones[i].name.split('/')[0] === browser.timezone.split('/')[0]
            ) {
                return timezones[i].id;
            }
        }

        // If only offset value matches.
        for (let i = 0; i < timezones.length; i += 1) {
            if (browser.offset === timezones[i].offset && timezones[i].id) {
                return timezones[i].id;
            }
        }

        return null;
    }
};

class LoginForm {
    constructor({
        isModal = false,
        onSuccessfulSubmit = null,
        title = null
    } = {}) {
        this.form = document.getElementById('login-form');
        if (!this.form) {
            //console.warn('Missing Login Form HTMLElement');
            return;
        }

        this.submitButton = this.form.querySelector('[type="submit"]');

        this.validator = new Validator(this.form, {
            inputs: {
                login: {
                    label: 'Email',
                    input: this.form.querySelector('[name="login"]'),
                    rules: ['required', 'valid_email', 'maxlength:255'],
                    error: {
                        messages: { required: 'An email address is required' }
                    }
                },
                password: {
                    label: 'Password',
                    input: this.form.querySelector('[name="password"]'),
                    rules: ['required'],
                    error: {
                        insertAfter: document.getElementById('password-input-group'),
                        customDataAttributes: {
                            style: 'margin-top:4px'
                        }
                    }
                }
            },
            allowSubmitButtonDisabling: true,
            buttonsToDisableWhenProcessing: [this.submitButton],
            submitButtons: [this.submitButton],
            onBeforeSubmit: () => this.onAuthenticateBeforeSubmit(),
            onSubmit: (e) => this.onSubmit()
        });

        this.showPasswordButton = this.form.querySelector('.form-password-show');

        this.hiddenCsrfTokenInput = this.form.querySelector(`[name="security_token"]`);

        this.signUpLink = this.form.querySelector('a[href="/signup"]');

        this.loginTitle = document.getElementById('login-form-title');

        this.title = title;

        this.loginAlertError = new LoginFormAlertError();

        this.attachEventListeners();

        this.setTimezoneInputValue();

        this.setOnSuccessfulSubmit(onSuccessfulSubmit);

        // If on login FPE
        if (!isModal) {
            this.focusEmail();
        }

        this.auth2 = null; // The Sign-In object.
        this.googleUser = null; // The current user.
        this.googleSSIButton = null;
    }

    get emailValue() {
        return this.form.querySelector('[name="login"]').value;
    }

    set title(title) {
        this.currentTitle = title || 'Log In to Voices';
        if (!this.loginTitle) this.loginTitle = document.getElementById('login-form-title');
        if (this.loginTitle) this.loginTitle.innerHTML = this.currentTitle;
    }

    setOnSuccessfulSubmit(callback) {
        this.onSuccessfulSubmit = (typeof callback === 'function')
                                  ? async (type, memberInfo) => await callback(type, memberInfo)
                                  : async (type, memberInfo) => {
                return true;
            };
    }

    /**
     * Handling the callback from google
     * @param {*} googleUser
     */
    handleCredentialResponse = (response) => {
        this.googleResponse = this.decodeJwtResponse(response.credential);
        if (!!this.googleResponse) {
            this.handleSocialSignOn('google');
        }
    };

    initializeGoogleSSI() {
        if (window.location.pathname === '/login' || window.location.pathname === '/studio') {
            window.onload = () => {
                google.accounts.id.initialize({
                    client_id: GOOGLE_CLIENT_ID,
                    callback: this.handleCredentialResponse
                });

                google.accounts.id.renderButton(
                    document.querySelector('#google-sign-in'),
                    {
                        // customization attributes
                        theme: 'outline',
                        width: 250,
                        text: 'continue_with'
                    }
                );
            };
        }

        document.addEventListener('click', (e) => {
            const target = e.target.closest('#login-instead');

            if (target) {
                google.accounts.id.initialize({
                    client_id: GOOGLE_CLIENT_ID,
                    callback: this.handleCredentialResponse
                });

                google.accounts.id.renderButton(
                    document.querySelector('#google-sign-in'),
                    {
                        // customization attributes
                        theme: 'outline',
                        width: 250,
                        text: 'continue_with'
                    }
                );
            }
        });

        document.querySelectorAll(`[data-door="log-in"]`)
                .forEach(el => {
                    el.addEventListener('click', e => {

                        google.accounts.id.initialize({
                            client_id: GOOGLE_CLIENT_ID,
                            callback: this.handleCredentialResponse
                        });

                        google.accounts.id.renderButton(
                            document.querySelector('#google-sign-in'),
                            {
                                // customization attributes
                                theme: 'outline',
                                width: 250,
                                text: 'continue_with'
                            }
                        );
                    });
                });
    }

    attachSignIn(element) {
        this.auth2.attachClickHandler(element, {},
            (function(googleUser) {
                this.handleGoogleCredentialResponse(googleUser);
            }).bind(this), function(error) {
                console.log(JSON.stringify(error, undefined, 2));
            });
    }

    _handleAppleIDSignInOnSuccessForLogin(data) {
        //handle successful response
        const appleResponse = this.decodeJwtResponse(data.authorization.id_token);
        if (appleResponse) {
            this.appleResponse = appleResponse;
        }
        const appleSSOBtn = document.getElementById('apple-sign-in-login');

        if (appleSSOBtn) {
            appleResponse['provider'] = 'apple';
            appleResponse['response-code'] = data.authorization.code;
            appleResponse['response-state'] = data.authorization.state;
        }

        if (!!appleResponse) {
            this.handleSocialSignOn('apple');
        }
    }

    /**
     * Decode google response
     * @param {*} token
     * @returns
     */
    decodeJwtResponse(token) {
        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload);
    }

    /**
     * Show form
     */
    show() {
        this.form.classList.remove('hidden');
        this.focusEmail();
    }

    /**
     * Hide form
     */
    hide() {
        this.form.classList.add('hidden');
    }

    /**
     * Update value of email field from switching between modal forms.
     * @param val     The value to update the input with.
     */
    updateEmail(val) {
        if (!val) return;
        const emailInput = this.form.querySelector('[name="login"]');
        emailInput.value = val;
    }

    /**
     * Focus the email field for a better UX
     */
    focusEmail() {
        const emailInput = this.form.querySelector('[name="login"]');
        if (emailInput) {
            emailInput.focus();
        }
    }

    /**
     * Attach Event Listeners to module
     */
    attachEventListeners() {

        if (this.showPasswordButton) {
            this.showPasswordButton.addEventListener('click', event => {
                const passwordInput = this.form.querySelector('[name="password"]');
                if (passwordInput.type === 'password') {
                    this.showPasswordButton.innerHTML = '<i class="fa fa-eye-slash"></i>';
                    this.showPasswordButton.dataset.originalTitle = 'Hide Password';
                    passwordInput.type = 'text';
                    event.stopImmediatePropagation();
                } else {
                    passwordInput.type = 'password';
                    this.showPasswordButton.innerHTML = '<i class="fa fa-eye"></i>';
                    this.showPasswordButton.dataset.originalTitle = 'Show Password';
                    event.stopImmediatePropagation();
                }
            });
        }

        const targetLoginFormHeaderButton = document.querySelector('#header [data-bs-target="#login-form"]');
        if (targetLoginFormHeaderButton) {
            targetLoginFormHeaderButton.addEventListener('click', event => {
                event.preventDefault();
                const emailInput = this.form.querySelector('[name="login"]');
                emailInput.focus();
            });
        }

        this.initializeGoogleSSI();

        /**
         * Apple SSO
         */

        //Configure the Apple Auth Object
        AppleID.auth.init({
            clientId: APPLE_CLIENT_ID,
            scope: 'name email',
            redirectURI: APPLE_REDIRECT_URI,
            usePopup: true //or false defaults to false
        });

        const loginAppleSSOBtn = document.getElementById('appleid-signin-login');
        loginAppleSSOBtn.addEventListener('click', e => {
            e.preventDefault();
            AppleID.auth.signIn().then(response => this._handleAppleIDSignInOnSuccessForLogin(response));
        });


        //Listen for authorization failures
        document.addEventListener('AppleIDSignInOnFailure', (error) => {
            //handle error.
            //console.log(error);
        });


    }

    /**
     * Generate Alert Error within Login Form
     * @param errorObject
     */
    generateAlertErrorForLoginForm(errorObject = {}) {
        let alertElement = document.getElementById('login-form-alert');
        if (!alertElement) {
            alertElement = this.loginAlertError.buildAlert();
            this.form.insertBefore(alertElement, this.form.querySelector('h2').nextSibling);
        }

        const alertTitle = alertElement.querySelector('#login-form-alert-title');
        if (alertTitle) {
            alertTitle.innerHTML = errorObject.title;
        }

        const alertContent = alertElement.querySelector('#login-form-alert-content');
        if (alertContent) {
            alertContent.innerHTML = errorObject.message;
        }

        const alertSignUpLink = document.querySelector(`#error-banner-signup-link`);
        if (alertSignUpLink) {
            alertSignUpLink.addEventListener('click', function(e) {
                e.preventDefault();
                if (document.getElementById('signup-instead')) {
                    document.getElementById('signup-instead').click();
                }
            });
        }
    }

    /**
     * Clear Alert Error within Login Form
     */
    clearAlertErrorForLoginForm() {
        const alertElement = document.getElementById('login-form-alert');
        if (alertElement && alertElement.parentNode) {
            alertElement.parentNode.removeChild(alertElement);
        }
    }

    /**
     * Set Timezone Value
     * @return {Promise<void>}
     */
    async setTimezoneInputValue() {
        const timezones = await fetch('/api/timezones')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Fetch Request Failed');
                }
                return response.json();
            })
            .then(json => json.data)
            .catch(err => {
                return null;
            });

        if (timezones.length) {
            const detectedTimezone = TimezoneDetector.detect(timezones);
            document.querySelector('[name="timezone_id"]').value = detectedTimezone;
        }
    }

    /**
     * Authenticate Login Credentials before performing login.
     * @return {Promise<null|boolean>}
     */
    async onAuthenticateBeforeSubmit() {
        const data = new FormData(this.form);
        return await fetch(`/login/authenticate_login`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            },
            body: data
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Fetch Request Failed');
                }
                return response.json();
            })
            .then(json => {
                const {
                    status,
                    error = null,
                    token: updatedSecurityToken = null
                } = json;

                // Need to update CSRF Token for each POST request, since it is regenerated for each request
                this.hiddenCsrfTokenInput.value = updatedSecurityToken;

                if (status === 'success') {
                    return true;
                }

                if (status === 'error' && error) {
                    switch (error) {
                        case 'member_is_not_active':
                            this.generateAlertErrorForLoginForm({
                                title: 'Error',
                                message: 'Your Account is no longer active. Please <a href="/service/customer_care">contact support</a> regarding the status of your account.'
                            });
                            break;
                        case 'last_attempt':
                            this.generateAlertErrorForLoginForm({
                                title: 'Login Incorrect - 1 Attempt Remaining',
                                message: 'Your account will be locked for 30 minutes after 5 attempts. <a href="/login/forgot">Reset password now</a>.'
                            });
                            break;
                        case 'login_attempts_locked':
                            this.generateAlertErrorForLoginForm({
                                title: 'Your Account Has Been Locked',
                                message: 'For security reasons, your account will be locked for 30 minutes. You can <a href="/login/forgot">reset your password now</a> to access your account right away or <a href="mailto:support@voices.com">contact support</a> for additional help.'
                            });
                            break;
                        default:
                            this.generateAlertErrorForLoginForm({
                                title: 'Unable to Log In',
                                message: 'Your email address and password did not match. Try again or <a href="/login/forgot">reset your password</a>.'
                            });
                            break;
                    }

                    return false;
                }
            })
            .catch(err => {
                return false;
            });
    }

    /**
     * Handle Login Submission
     * @return {Promise<void>}
     */
    async onSubmit() {
        // Clear Alert Error - if it is currently displayed
        this.clearAlertErrorForLoginForm();

        // Login Form Data - values retrieved from <inputs>
        const data = new FormData(this.form);

        // Send Fetch Request
        await fetch(this.form.action, {
            method: 'POST',
            body: data,
            headers: {
                'Accept': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Fetch Request Failed');
                }

                return response.json();
            })
            .then(async (json) => {
                const {
                    status,
                    data: {
                        redirect_url: redirectUrl = null
                    } = {},
                    token: updatedSecurityToken = null
                } = json;

                // Need to update CSRF Token if POST requests fail, since it is regenerated for each request
                this.hiddenCsrfTokenInput.value = updatedSecurityToken;

                if (status === 'success' && redirectUrl) {
                    if (await this.onSuccessfulSubmit('login', json.data)) {
                        window.location.href = redirectUrl;
                    }
                }

                //MFA enabled user - Login error for Pre-Reg JobForm
                if (status === 'error' && json.type === 'mfa_error') {
                    this.generateAlertErrorForLoginForm({
                        title: 'Error',
                        message: 'There was an issue regarding multi-factor authentication. Please exit and <a href="/login">login</a> here, then post your job. Sorry for the inconvenience.'
                    });
                }
            })
            .catch(err => {

                this.generateAlertErrorForLoginForm({
                    title: 'Error',
                    message: 'Something went wrong. Please try again shortly.'
                });
            });
    }

    /**
     * Handle SSO
     * - Google
     * - Apple
     * @return {Promise<void>}
     */
    handleSocialSignOn(social) {

        const availableSocials = ['google', 'apple'];
        if (!availableSocials.includes(social)) {
            return;
        }

        const formData = new FormData(this.form);

        if (social === 'apple') {
            this.responsePayload = this.appleResponse;
        }

        if (social === 'google') {
            this.responsePayload = this.googleResponse;
        }

        //Append SSOresponsePayload to the formData
        if (!!this.responsePayload) {
            Object.entries(this.responsePayload).forEach(([key, value]) => {
                formData.append(key, value);
            });
        }

        fetch(`/login/social/${social}`, {
            method: 'POST',
            body: formData,
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Fetch Request Failed');
                }
                return response.json();
            })
            .then(async (json) => {
                const {
                    status
                } = json;

                //Success Login 
                if (status === 'success' && json.redirect_url) {
                    if (await this.onSuccessfulSubmit('login', json.data)) {

                        window.location.href = json.redirect_url;
                    }
                }

                if (status === 'error') {
                    if (json.message.generic_error) {
                        if (json.token && this.hiddenCsrfTokenInput) {
                            this.hiddenCsrfTokenInput.value = json.token;
                        }
                        this.generateAlertErrorForLoginForm({
                            title: 'Error',
                            message: json.message.generic_error
                        });
                    }

                    if (json.message.type === 'login_account_is_not_exist') {
                        this.generateAlertErrorForLoginForm({
                            title: 'Unable to Log In',
                            message: 'Email or username is not registered. Please try another email or <a id="error-banner-signup-link" href="/signup">sign up.</a>'
                        });
                    }
                }
            })
            .catch(err => {
                this.generateAlertErrorForLoginForm({
                    title: 'Error',
                    message: 'Something went wrong. Failed to continue with Social Sign On.'
                });
            });
    }
}

module.exports = LoginForm;
