const creditCards = require('./creditCards');

const DEFAULT_SECURITY_CODE_LENGTH = 3;

class CreditCardValidatorHelper {

    /**
     * Returns the Credit Card name.
     *
     * @param {string}     number     Credit Card number
     * @returns string
     */
    static getCreditCardNameByNumber(number) {
        return this.findCreditCardObjectByNumber(number).name;
    }

    /**
     * Validate if the Credit Card Number is Valid.
     *
     * @param {string}     number         Credit Card number
     * @returns bool|boolean
     */
    static isCardNumberValid(number) {
        const rawNumber = number.replace(/\D/g, '');

        if (this.hasSomeInvalidDigit(number) || !this.hasCorrectLength(rawNumber)) {
            return false;
        }

        return (
            creditCards.map((c) => c.name)
                .includes(this.getCreditCardNameByNumber(number))
        );
    }


    /**
     * Validate if the Credit Card Type is Accepted.
     *
     * @param {string}     number         credit card number
     * @returns bool|boolean
     */
    static isCreditCardAccepted(number) {
        const rawNumber = number.replace(/\D/g, '');

        if (this.hasSomeInvalidDigit(number) || !this.hasCorrectLength(rawNumber)) {
            return false;
        }

        return (
            creditCards.filter(card => card.accepted)
                .map((c) => c.name)
                .includes(this.getCreditCardNameByNumber(number))
        );
    }

    /**
     * Validate if the Credit Card Security Code is Valid.
     *
     * @param {string}     creditCardNumber     Credit Card number
     * @param {string}     securityCode         Security Code number
     * @returns bool|boolean
     */
    static isSecurityCodeValid(creditCardNumber, securityCode) {
        const numberLength = this.getCreditCardSecurityCodeLengthByNumber(creditCardNumber);
        return new RegExp(`^[0-9]{${numberLength}}$`).test(securityCode);
    }

    /**
     * Returns the Credit Card Object by Credit Card Number.
     *
     * @param {string}  number        credit card number
     * @returns object
     */
    static findCreditCardObjectByNumber(number) {
        if (!number) return {};
        const numberOnly = number.replace(/[^\d]/g, '');
        return creditCards.find((card) => card.regex.test(numberOnly) && card) || {};
    }

    /**
     * Returns the Security Code Length based on the Credit Card Type.
     *
     * @param {string|null}  number        credit card number
     * @returns int
     */
    static getCreditCardSecurityCodeLengthByNumber(number = null) {
        return (number) ? this.findCreditCardObjectByNumber(number).securityCodeLength : DEFAULT_SECURITY_CODE_LENGTH;
    }

    /**
     * Validate if the Credit Card Month is between 01 and 12.
     *
     * @param {int}  month        credit card expiry month, in double digit format including 0
     * @returns bool|boolean
     */
    static isValidMonth(month) {
        return !isNaN(month) && (month >= 1 && month <= 12);
    }

    /**
     * Validate if the Credit Card Year is not over 20 years in the future.
     *
     * @param {int}  year        credit card expiry year, in double digit format including 0
     * @returns bool|boolean
     */
    static isValidYear(year) {
        const fullYear = this.formatFullYear(year);
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();

        return !isNaN(year) && !(fullYear >= currentYear + 20);
    }

    /**
     * Validate if card expiration date is present or future date.
     *
     * @param {int}  month       credit card expiry month, in double digit format including 0
     * @param {int}  year        credit card expiry year, in double digit format including 0
     * @returns bool|boolean
     */
    static isFutureOrPresentDate(month, year) {
        const fullYear = this.formatFullYear(year);
        const currentDate = new Date();
        const expirationDate = new Date();

        currentDate.setFullYear(currentDate.getFullYear(), currentDate.getMonth(), 1);
        expirationDate.setFullYear(fullYear, month - 1, 1);

        return currentDate <= expirationDate;
    }

    /**
     * Validates if the Credit Card number has the correct length.
     *
     * @param {string}     number         credit card number
     * @returns bool|boolean
     */
    static hasCorrectLength(number) {
        return number && number.length <= 19;
    }

    /**
     * Validates if the Credit Card number has an invalid digit.
     *
     * @param {string}     number         credit card number
     * @returns bool|boolean
     */
    static hasSomeInvalidDigit(number) {
        const invalidDigits = new RegExp('[^0-9- ]');
        return invalidDigits.test(number);
    }

    /**
     * Format double digit year to full year.
     *
     * @param {int|string}  year    credit card expiry year, in double digit format including 0
     * @returns int
     */
    static formatFullYear(year = 0) {
        const today = new Date();
        return Math.floor(today.getFullYear() / 1000) * 1000 + parseInt(year);
    }
}

module.exports = CreditCardValidatorHelper;
