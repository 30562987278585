const ValidationFunctions = require('../../../sharable/Validator/ValidationFunctions');
const ValidationError = require('../../../sharable/Validator/ValidationError');

class PasswordValidationWidget {
    constructor(input){
        if(!input){
            throw new Error('Password input not provided');
        }

        this.input = input;

        this.renderValidationPattern();

        this.passwordStrengthUI = document.getElementById('password-validation-pattern');

        this.fn = new ValidationFunctions();
        this.addEventListeners();
    }

    get VALIDATION_RULES(){
        return [
            'required',
            'maxlength:128',
            'minlength:8',
            'requires_uppercase',
            'requires_lowercase',
            'requires_number',
            'requires_special_character'
        ]; 
    }

    get VALIDATION_OBJECT(){
        const validationObj = {
            [this.input.name] : {
                label: 'Password',
                rules: this.VALIDATION_RULES,
                error: {
                    insertAfter: this.passwordStrengthUI,
                }
            }
        };
        return validationObj;
    }

    beforeDisplayErrors(el, rule){
        if(el !== this.input) return true;
        if(rule === 'required' || rule === 'maxlength:128') return true;

        return false;
    }

    afterFormValidate(validationErrors){
        if(this.input.name in validationErrors && !this.passwordStrengthUI.classList.contains('hidden')){
            if(validationErrors[this.input.name].length === 1 && validationErrors[this.input.name][0][0] === 'required'){
                document.querySelectorAll('.password-validation-item').forEach(item => {
                    item.classList.add('error');
                });
            }else{
                validationErrors[this.input.name].forEach( error => {
                    document.querySelector(`.password-validation-item[data-rule="${error[0]}"]`).classList.add('error');
                });
            }

        }
    }

    renderValidationPattern(){
        const html = `<ul id="password-validation-pattern" class="hidden">
            <li class="password-validation-item" data-rule="minlength:8">
                At least 8 characters
            </li>
            <li class="password-validation-item" data-rule="requires_uppercase">
                1 uppercase
            </li>
            <li class="password-validation-item" data-rule="requires_lowercase">
                1 lowercase
            </li>
            <li class="password-validation-item" data-rule="requires_number">
                1 number
            </li>
            <li class="password-validation-item" data-rule="requires_special_character">
                1 symbol
            </li>
        </ul>`;
        this.input.parentNode.insertAdjacentHTML('afterend', html);
    }

    requiredPass(){
        const pass = this.fn.required(this.input);
        return (pass instanceof ValidationError);
    }

    addEventListeners(){
        this.input.addEventListener('focus', () => {
            let checkedCount = document.querySelectorAll('#password-validation-pattern .checked').length;
            if(checkedCount !== 5){
                this.passwordStrengthUI.classList.remove('hidden');
            }
        });

        document.body.addEventListener('click', (e) => {
            if(e.target.id !== "password-signup") {
                if(!this.passwordStrengthUI.classList.contains('hidden')
                && !document.querySelector(`.valid-password`).classList.contains('hidden')){
                    this.passwordStrengthUI.classList.add('hidden');
                }
            }
        });

        this.input.addEventListener('input', () => {
            if(this.input.value === ''){
                document.querySelectorAll('.password-validation-item').forEach(validation => {
                    validation.classList.remove('checked');
                    validation.classList.remove('error');
                    document.querySelector(`.valid-password`).classList.add('hidden');
                    this.input.style.textIndent = '0px';
                });
                return;
            }else{
                this.passwordStrengthUI.classList.remove('hidden');
            }
            this.VALIDATION_RULES.forEach(rule => {
                let pass = null;
                switch(rule){
                    case 'minlength:8':
                        const [, limit] = rule.split(':');
                        pass = this.fn.minlength(this.input, {
                            limit: parseInt(limit)
                        });
                        break;
                    case 'requires_uppercase':
                    case 'requires_lowercase':
                    case 'requires_number':
                    case 'requires_special_character':
                        pass = this.fn[rule](this.input);
                    case 'required':
                    case 'maxlength:128':
                    default:
                        break;
                }
                
                if(pass){
                    if(!(pass instanceof ValidationError)){
                        document.querySelector(`.password-validation-item[data-rule="${rule}"]`).classList.remove('error');
                        document.querySelector(`.password-validation-item[data-rule="${rule}"]`).classList.add('checked');
                        let checkedCount = document.querySelectorAll('#password-validation-pattern .checked').length;
                        if(checkedCount === 5){
                            document.querySelector(`.valid-password`).classList.remove('hidden');
                            this.input.style.textIndent= '20px';
                        }
                    }else{
                        document.querySelector(`.password-validation-item[data-rule="${rule}"]`).classList.remove('checked');
                        document.querySelector(`.valid-password`).classList.add('hidden');
                        this.input.style.textIndent = '0px';
                    }
                }
            })
        });
    }

}
module.exports = PasswordValidationWidget;