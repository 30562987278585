const acceptedFormattingLink = '<a href="https://www.voices.com/help/knowledge/faq/talent-profiles" target="_blank">View Accepted Formatting</a>';

module.exports = {
    /**
     * Error Message: Required
     * @param name
     * @return {string}
     */
    required: ({ name = null } = {}) => {
        if (name) {
            return `${name} is required`;
        }

        return 'Field is required';
    },

    /**
     * Error Message: Required when Other Input includes a specific value
     * @param name
     * @return {string}
     */
    required_when: ({ name = null } = {}) => {
        if (name) {
            return `${name} is required`;
        }

        return `Field is required`;
    },

    /**
     * Error Message: Required when any of the grouped elements have been filled in
     * @param name
     * @return {string}
     */
    required_as_group: ({ name = null } = {}) => {
        if (name) {
            return `${name} is required`;
        }

        return `Field is required`;
    },

    /**
     * Error Message: Require one of the group to be filled in
     * @param name
     * @return {string}
     */
    require_one_of_group: ({ name = null } = {}) => {
        if (name) {
            return `${name} requires at least one value`;
        }

        return `At least one field is required`;
    },

    /**
     * Error Message: Input is not equal to
     * @param name
     * @return {string}
     */
    not_equal_to: ({ name = null, values } = {}) => {
        if (name) {
            return `${name} cannot be ${values.join(' or ')}`;
        }

        return `Cannot be ${values.join(' or ')}`;
    },

    /**
     * Error Message: Maximum Length of Characters
     * @param name
     * @param limit
     * @return {string}
     */
    maxlength: ({ name = null, limit } = {}) => {
        if (name) {
            return `${name} cannot exceed ${limit} characters`;
        }

        return `Cannot exceed ${limit} characters`;
    },

    /**
     * Error Message: Minimum Length of Characters
     * @param name
     * @param limit
     * @return {string}
     */
    minlength: ({ name = null, limit } = {}) => {
        if (name) {
            return `${name} must be at least ${limit} characters`;
        }

        return `Must be at least ${limit} characters`;
    },

    /**
     * Error Message: Maximum Value of a specific amount
     * @param name
     * @param amount
     * @return {string}
     */
    max_value: ({ name = null, amount } = {}) => {
        if (name) {
            return `${name} cannot exceed ${amount}`;
        }

        return `Cannot exceed ${amount}`;
    },

    /**
     * Error Message: Minimum Value of a specific amount
     * @param name
     * @param amount
     * @return {string}
     */
    min_value: ({ name = null, amount } = {}) => {
        if (name) {
            return `${name} must be at least ${amount}`;
        }

        return `Must be at least ${amount}`;
    },

    /**
     * Error Message: min_date_today
     * @param name
     * @return {string}
     */
    min_date_today: ({ name = null } = {}) => {
        if (name) {
            return `${name} date has passed`;
        }

        return `Date has passed`;
    },

    /**
     * Error Message: Check if value is a decimal number?
     * @param name
     * @param limit
     * @return {string}
     */
    valid_decimal: ({ name = null } = {}) => {
        if (name) {
            return `${name} is not a valid decimal number`;
        }

        return `Value is not a valid decimal number`;
    },

    /**
     * Error Message: Alpha Dash
     * @param name
     * @return {string}
     */
    alpha_dash: ({ name = null } = {}) => {
        if (name) {
            return `${name} can only contain alpha-numeric characters, underscores or dashes.`;
        }

        return `Input can only contain alpha-numeric characters, underscores or dashes.`;
    },

    /**
     * Error Message: No UTF-8
     * @param name
     * @return {string}
     */
    no_utf8mb4: ({ name = null } = {}) => {
        if (name) {
            return `${name} contains invalid characters.`;
        }

        return `Input contains invalid characters.`;
    },

    /**
     * Error Message: Alpha Dash Space
     * @param name
     * @return {string}
     */
    alpha_dash_space: ({ name = null } = {}) => {
        if (name) {
            return `${name} can only contain alpha-numeric characters, underscores, dashes or spaces.`;
        }

        return `Input can only contain alpha-numeric characters, underscores, dashes or spaces.`;
    },

    /**
     * Error Message: Disallow Only Dashes and/or Underscore
     * @param name
     * @return {string}
     */
    not_only_dashes_underscore: ({ name = null } = {}) => {
        if (name) {
            return `${name} cannot contain only underscores or dashes.`;
        }

        return `Input cannot contain only underscores or dashes.`;
    },

    /**
     * Error Message: Website
     * @param name
     * @return {string}
     */
    valid_website: ({ name = null } = {}) => {
        if (name) {
            return `${name} is not a valid URL`;
        }

        return 'Please enter a valid URL';
    },

    /**
     * Error Message: Email
     * @param name
     * @return {string}
     */
    valid_email: ({ name = null } = {}) => {
        if (name) {
            return `${name} is not a valid email address`;
        }

        return 'Please enter a valid email';
    },

    /**
     * Error Message: Phone
     * @param name
     * @return {string}
     */
    valid_phone: ({ name = null } = {}) => {
        if (name) {
            return `${name} is not a valid phone number`;
        }

        return 'Please enter a valid phone number';
    },


    /**
     * Error Message: State / Province
     * @param name
     * @return {string}
     */
    valid_state: ({ name = null } = {}) => {
        if (name) {
            return `${name} is required`;
        }

        return 'State / Province is required';
    },

    /**
     * Error Message: Sanitization Failed for "text"
     * @param name
     * @return {string}
     */
    sanitized_text: ({ name = null } = {}) => {
        if (name) {
            return `${name} cannot contain special characters`;
        }

        return 'Input cannot contain special characters';
    },

    /**
     * Error Message: Sanitization Failed for "text"
     * @param name
     * @return {string}
     */
    sanitized_text_not_starting_with_quotes: ({ name = null } = {}) => {
        if (name) {
            return `${name} cannot begin with quotes`;
        }
        return 'Input cannot begin with quotes';
    },

    /**
     * Error Message: Sanitization Failed for "requires_uppercase"
     * @param name
     * @return {string}
     */
    requires_uppercase: ({ name = null } = {}) => {
        if (name) {
            return `${name} must have at least one uppercase letter`;
        }

        return 'Input must have at least one uppercase letter';
    },

    /**
     * Error Message: Sanitization Failed for "requires_lowercase"
     * @param name
     * @return {string}
     */
    requires_lowercase: ({ name = null } = {}) => {
        if (name) {
            return `${name} must have at least one lowercase letter`;
        }

        return 'Input must have at least one lowercase letter';
    },

    /**
     * Error Message: Sanitization Failed for "requires_number"
     * @param name
     * @return {string}
     */
    requires_number: ({ name = null } = {}) => {
        if (name) {
            return `${name} must have at least one number`;
        }

        return 'Input must have at least one number';
    },

    /**
     * Error Message: Sanitization Failed for "requires_alphanumeric"
     * @param name
     * @return {string}
     */
     requires_alphanumeric: ({ name = null } = {}) => {
        if (name) {
            return `${name} must contain one letter or one number`;
        }

        return 'Input must contain one letter or one number';
    },

    /**
     * Error Message: Sanitization Failed for "requires_special_character"
     * @param name
     * @return {string}
     */
    requires_special_character: ({ name = null } = {}) => {
        if (name) {
            return `${name} must have at least one special character`;
        }

        return 'Input must have at least one special character';
    },


    /**
     * Error Message: Sanitization Failed for "emoji"
     * @param name
     * @return {string}
     */
    no_emojis: ({ name = null } = {}) => {
        if (name) {
            return `<span>${name} cannot contain special characters - ${acceptedFormattingLink}</span>`;
        }

        return '<span>Input cannot contain special characters - ${acceptedFormattingLink}</span>';
    },


    /**
     * Error Message: Sanitization Failed for "alt code"
     * @param name
     * @return {string}
     */
    no_alt_codes: ({ name = null } = {}) => {
        if (name) {
            return `<span>${name} cannot contain special characters - ${acceptedFormattingLink}</span>`;
        }

        return '<span>Input cannot contain special characters - ${acceptedFormattingLink}</span>';
    },

    /**
     * Error Message: Sanitization Failed for "username"
     * @param name
     * @return {string}
     */
    valid_username: ({ name = null } = {}) => {
        if (name) {
            return `${name} can only contain lowercase alpha-numeric characters and dashes`;
        }

        return 'Username can only contain lowercase alpha-numeric characters and dashes';
    },

    /**
     * Error Message: MemberViolation Checks
     * - Cannot contain email
     * - Cannot contain phone number
     * - Cannot contain website
     * @param name
     * @param ignore        {array}     List of Member Violation rules to ignore. Options: 'email', 'phone', 'website'
     * @return {string}
     */
    check_member_violations: ({ name = null, ignore = [] } = {}) => {
        let msg;

        // email only
        if (
            !ignore.includes('email')
            && ignore.includes('phone')
            && ignore.includes('website')
        ) {
            msg = 'cannot contain any emails';
        }

        // phone only
        if (
            ignore.includes('email')
            && !ignore.includes('phone')
            && ignore.includes('website')
        ) {
            msg = 'cannot contain any phone numbers';
        }

        // website only
        if (
            ignore.includes('email')
            && ignore.includes('phone')
            && !ignore.includes('website')
        ) {
            msg = 'cannot contain any websites';
        }

        // email and phone
        if (
            !ignore.includes('email')
            && !ignore.includes('phone')
            && ignore.includes('website')
        ) {
            msg = 'cannot contain any emails or phone numbers';
        }

        // email and website
        if (
            !ignore.includes('email')
            && ignore.includes('phone')
            && !ignore.includes('website')
        ) {
            msg = 'cannot contain any emails or websites';
        }

        // phone and website
        if (
            ignore.includes('email')
            && !ignore.includes('phone')
            && !ignore.includes('website')
        ) {
            msg = 'cannot contain any phone numbers or websites';
        }

        // email, phone, and website
        if (
            !ignore.includes('email')
            && !ignore.includes('phone')
            && !ignore.includes('website')
        ) {
            msg = 'cannot contain any emails, phone numbers, or websites';
        }


        if (name) {
            return `${name} ${msg}`;
        }

        return `Input ${msg}`;
    },

    /**
     * Error Message: Input Value Needs to Match Another Input's Value
     * @param name
     * @param otherElement
     * @return {string}
     */
    matches_value: ({ name = null, otherElementName } = {}) => {
        if (name) {
            return `${name} needs to match ${otherElementName} value`;
        }

        return `Needs to match ${otherElementName} value`;
    },

    /**
     * Error Message: Validation Failed for "valid_credit_card_number"
     * @param name
     * @return {string}
     */
    valid_credit_card_number: ({ name = null } = {}) => {
        if (name) {
            return `${name} is invalid`;
        }

        return 'Card Number is invalid';
    },

    /**
     * Error Message: Validation Failed for "accepted_credit_card"
     * @return {string}
     */
    accepted_credit_card: ({ name = null, cardName =  null } = {}) => {
        if (cardName) {
            return `${cardName} card is not accepted`;
        }

        return 'Credit Card not accepted';
    },

    /**
     * Error Message: Validation Failed for "valid_credit_card_security_code"
     * @param name
     * @return {string}
     */
    valid_credit_card_security_code: ({ name = null } = {}) => {
        if (name) {
            return `${name} is invalid`;
        }

        return 'Security Code is invalid';
    },

    /**
     * Error Message: Validation Failed for "valid_credit_card_expiration"
     * @param name
     * @return {string}
     */
    valid_credit_card_expiration: () => {
        return 'Card is expired';
    },

    /**
     * Error Message: Validation Failed for "valid_credit_card_expiration_month"
     * @return {string}
     */
    valid_credit_card_expiration_month: () => {
        return 'Month must be between 01 and 12';
    },

    /**
     * Error Message: Validation Failed for "valid_credit_card_expiration_year"
     * @return {string}
     */
    valid_credit_card_expiration_year: () => {
        return 'Year is too far into the future';
    },

    /**
     * Error Message: Validation failed when validating a date format field.
     * @return {string}
     */
    valid_date_format: ({ name = null } = {}) => {
        if (name) {
            return `${name} is an invalid date`;
        }

        return 'Input is an invalid date';
    },

    /**
     * Error Message: Validation failed when validating a date format field.
     * @return {string}
     */
    valid_date_format_mmddyyyy: ({ name = null } = {}) => {
        if (name) {
            return `${name} is an invalid MM/DD/YYYY date`;
        }

        return 'Input is an invalid MM/DD/YYYY date';
    },

    /**
     * Error Message: Validation failed when validating a date format field.
     * @return {string}
     */
    valid_date_format_yyyymmdd: ({ name = null } = {}) => {
        if (name) {
            return `${name} is an invalid YYYY-MM-DD date`;
        }

        return 'Input is an invalid YYYY-MM-DD date';
    },

    /**
     * Error Message: Ajax Validation
     * @param name
     * @return {string}
     */
    ajax_validation: ({ name = null } = {}) => {
        if (name) {
            return `${name} cannot be used`;
        }

        return 'Cannot be used';
    }
};
