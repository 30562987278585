const ValidationError = require('./ValidationError');
const CreditCardValidatorHelper = require('./Implementations/creditCardValidator/CreditCardValidatorHelper');
const regexPatterns = require('@js/utilities/regexPatterns');
const { isEmpty } = require('./utilities');
const Sanitizer = require('../Sanitizer');
const moment = require('moment-timezone');

const is_valid_decimal = (value) => !(
    isNaN(parseFloat(value))
    || (
        Array.isArray(value.match(/\./g))
        && value.match(/\./g).length > 1
    )
);

/**
 * Build out all core Validator validation functions
 */
class ValidationFunctions {

    /**
     * Validation Functions Constructor
     */
    constructor() {
        this.Sanitizer = new Sanitizer();
    }

    /**
     * Validate - Input Field contains some sort of value
     * @param el                    {HTMLElement}   Input being validated
     * @param customErrorMessage    {string}        Overwrite Default Error Message
     * @return {*}
     */
    required(el, {
        customErrorMessage = null
    } = {}) {
        if (isEmpty(el)) {
            return new ValidationError(el, {
                error: 'required',
                customErrorMessage
            });
        }

        return true;
    }

    /**
     * Validate - Some other Input's value matches a specific value, so this Input is required
     * @param currentElement        {HTMLElement}   Input being validated
     * @param element               {HTMLElement}   Input Element this Input must be validated with
     * @param elements              {HTMLElement[]} Input Elements (within group) this Input must be validated with
     * @param isEqualTo             {*}             Property value(s) that are accepted for the "required with property"
     * @param isNotEqualTo          {array}         Property value(s) that are NOT accepted for the "required with property"
     * @param isEmptyOrNotEqualTo   {array}         First, check if isEmpty=true, if false, then check the property value(s) and ensure they do not match any of the forbidden values listed
     * @param isEmpty               {boolean}       In order for current element to be required, other element(s) must be empty
     * @param type                  {*}             The type of comparison we are doing. Defaults to 'value'. Accepts: 'style', 'attr'
     * @param strict                {boolean}       use strict type comparison to check which values are accepted
     * @param customErrorMessage    {string}        Overwrite Default Error Message
     * @return {*}
     */
    required_when(currentElement, {
        element: otherElement = null,
        elements: otherElements = [],
        isEqualTo = null,
        isNotEqualTo = [],
        isEmptyOrNotEqualTo = [],
        isEmpty: isEmptyRequired = false,
        type = 'value',
        strict = false,
        customErrorMessage = null
    } = {}) {
        // At least 1 condition must be defined. These are all the default values in the function params.
        if (
            isEqualTo === null
            && isEmptyRequired === false
            && (Array.isArray(isNotEqualTo) && isNotEqualTo.length === 0)
            && (Array.isArray(isEmptyOrNotEqualTo) && isEmptyOrNotEqualTo.length === 0)
        ) {
            console.error('Failed to validate input, missing required function parameters');
            return;
        }

        // Other element doesn't exist on DOM? We will assume this field to be valid then, since we have nothing to compare to
        if (!otherElement && otherElements.length === 0) {
            return true;
        }

        // Filter out elements which dont exist on DOM.
        if (otherElements.length >= 1) {
            otherElements = otherElements.filter(otherElementFromArray => !!otherElementFromArray);
        }

        const otherElementValueIsMatched = () => {
            if (type === 'value') {
                /**
                 * Get other Values, which will be compared against the accepted values
                 * @returns {*[]}
                 */
                const getOtherElementValues = () => {
                    const otherElementValues = [];
                    const getOtherElementValue = (el) => {
                        switch (el.type) {
                            case 'radio':
                            case 'checkbox':
                                return el.checked;
                            default:
                                return el.value;
                        }
                    };
                    if (otherElements.length === 0 && !!otherElement) {
                        otherElementValues.push(getOtherElementValue(otherElement));
                    }
                    else if (otherElements.length >= 1) {
                        otherElements.forEach(otherElementFromArray => {
                            otherElementValues.push(getOtherElementValue(otherElementFromArray));
                        });
                    }
                    return otherElementValues;
                };

                const otherElementValues = getOtherElementValues();
                const acceptableValues = Array.isArray(isEqualTo) ? isEqualTo : [isEqualTo];
                let forbiddenValues = [];
                if (
                    Array.isArray(isNotEqualTo)
                    && isNotEqualTo.length > 0
                    && Array.isArray(isEmptyOrNotEqualTo)
                    && isEmptyOrNotEqualTo.length === 0
                ) {
                    forbiddenValues = isNotEqualTo;
                }
                else if (
                    Array.isArray(isNotEqualTo)
                    && isNotEqualTo.length === 0
                    && Array.isArray(isEmptyOrNotEqualTo)
                    && isEmptyOrNotEqualTo.length > 0
                ) {
                    forbiddenValues = isEmptyOrNotEqualTo;
                }

                const isCurrentElementRequiredToBeEmpty = (
                    // Check isEmptyRequired variable?
                    isEmptyRequired
                    // Other element(s) are currently empty, but since we also need to check for forbidden values, isEmptyRequired will be false.
                    || (
                        isEmptyOrNotEqualTo
                        && forbiddenValues.length > 0
                        && Boolean(otherElementValues.every(otherElementValue => (otherElementValue === '' || !otherElementValue)))
                    )
                );

                const hasAcceptableValues = acceptableValues.some(acceptableValue => {
                    if (isCurrentElementRequiredToBeEmpty) {
                        return otherElementValues.every(otherElementValue => (otherElementValue === '' || !otherElementValue));
                    }
                    else if (!isCurrentElementRequiredToBeEmpty && !strict) {
                        return Boolean(otherElementValues.find(otherElementValue => acceptableValue == otherElementValue));
                    }
                    else {
                        return Boolean(otherElementValues.find(otherElementValue => acceptableValue === otherElementValue));
                    }
                });

                const hasForbiddenValues = forbiddenValues.some(forbiddenValue => {
                    if (!isCurrentElementRequiredToBeEmpty && !strict) {
                        return Boolean(otherElementValues.find(otherElementValue => forbiddenValue == otherElementValue));
                    }
                    else {
                        return Boolean(otherElementValues.find(otherElementValue => forbiddenValue === otherElementValue));
                    }
                });

                return (
                    (acceptableValues.length && hasAcceptableValues)
                    || (forbiddenValues.length && !hasForbiddenValues)
                );
            }
            // Style must match.
            // `isEqualTo` must be an obj for style requirements { styleAttribute : value }
            else if (type === 'style') {
                if (otherElements.length >= 1) {
                    return false; // TODO - enable style matching for multiple other elements? For now, this is unnecessary.
                }
                return Object.keys(isEqualTo).some( style => {
                    return otherElement.style[style] == isEqualTo[style];
                });
            }
            // DOM Attribute must match
            // `isEqualTo` must be an obj for attribute requirements { attributeName : value }
            else if (type === 'attr') {
                if (otherElements.length >= 1) {
                    return false; // TODO - enable attribute matching for multiple other elements? For now, this is unnecessary.
                }
                return Object.keys(isEqualTo).some( attr => {
                    const attributeValue = otherElement.getAttribute(attr);
                    return attributeValue == isEqualTo[attr];
                });
            }

            // Something went wrong
            return false;
        };

        if (isEmpty(currentElement) && otherElementValueIsMatched()) {
            return new ValidationError(currentElement, {
                error: 'required_when',
                customErrorMessage
            });
        }

        return true;
    }

    /**
     * Validate - This input is required when any other input within this group has a value entered.
     * @param currentElement        {HTMLElement}   Input being validated
     * @param elements              {array}         Input Elements this Input must be validated with
     * @param customErrorMessage    {string}        Overwrite Default Error Message
     * @return {*}
     */
    required_as_group(currentElement, {
        elements = [],
        customErrorMessage = null
    } = {}) {
        const someElementsInGroupAreFilledIn = elements.some(groupedElement => {
            let groupedElementValue;
            switch (groupedElement.type) {
                case 'checkbox':
                    groupedElementValue = groupedElement.checked;
                    break;
                default:
                    groupedElementValue = groupedElement.value;
                    break;
            }

            return !!groupedElementValue; // has some value?
        });

        if (isEmpty(currentElement) && someElementsInGroupAreFilledIn) {
            return new ValidationError(currentElement, {
                error: 'required_as_group',
                customErrorMessage
            });
        }

        return true;
    }

    /**
     * Validate - This input being populated requires a selection to be made within a group of others
     * @param currentElement        {HTMLElement}   Input being validated
     * @param elements              {array}         Input Elements this Input must be validated with
     * @param customErrorMessage    {string}        Overwrite Default Error Message
     * @return {*}
     */
    require_one_of_group(currentElement, {
        elements = [],
        customErrorMessage = null
    } = {}) {
        const someElementsInGroupAreFilledIn = elements.some(groupedElement => {
            let groupedElementValue;
            switch (groupedElement.type) {
                case 'checkbox':
                    groupedElementValue = groupedElement.checked;
                    break;
                default:
                    groupedElementValue = groupedElement.value;
                    break;
            }

            return !!groupedElementValue; // has some value?
        });

        //If both the current element is populated but none of the group elements are.
        if (!isEmpty(currentElement) && !someElementsInGroupAreFilledIn) {
            return new ValidationError(currentElement, {
                error: 'require_one_of_group',
                customErrorMessage
            });
        }

        return true;
    }

    /**
     * Validate - If element value is not equal to any of the forbidden values.
     * @param el
     * @param values
     * @param customErrorMessage
     * @returns {ValidationError|boolean}
     */
    not_equal_to(el, {
        values = [],
        customErrorMessage
    } = {}) {
        const currentValue = el.value;
        const forbiddenValues = Array.isArray(values) ? values : [values];
        const valueIsForbidden = forbiddenValues.some(forbiddenValue => Boolean(currentValue === forbiddenValue));

        if (valueIsForbidden) {
            return new ValidationError(el, {
                error: 'not_equal_to',
                customErrorMessage,
                params: {
                    values
                }
            });
        }

        // All good!
        return true;
    }

    /**
     * Validate - Maximum Character Length
     * @param el                    {HTMLElement}   Input being validated
     * @param limit                 {int}           Max Character Limit
     * @param customErrorMessage    {string}        Overwrite Default Error Message
     * @return {*}
     */
    maxlength(el, {
        limit,
        customErrorMessage = null
    } = {}) {
        if (!!el.value && (el.value.length > limit)) {
            return new ValidationError(el, {
                error: 'maxlength',
                customErrorMessage,
                params: {
                    limit
                }
            });
        }

        return true;
    }

    /**
     * Validate - Minimum Character Length
     * @param el                    {HTMLElement}   Input being validated
     * @param limit                 {int}           Min Character Limit
     * @param customErrorMessage    {string}        Overwrite Default Error Message
     * @return {*}
     */
    minlength(el, {
        limit,
        customErrorMessage = null
    } = {}) {
        if (!!el.value && (el.value.length < limit)) {
            return new ValidationError(el, {
                error: 'minlength',
                customErrorMessage,
                params: {
                    limit
                }
            });
        }

        return true;
    }

    /**
     * Validate - Maximum Value of a specific amount
     * @param el                    {HTMLElement}   Input being validated
     * @param amount                {int}           Max (Number or Money) Value
     * @param customErrorMessage    {string}        Overwrite Default Error Message
     * @return {*}
     */
    max_value(el, {
        amount,
        customErrorMessage = null
    } = {}) {
        let number = amount;
        if (amount.indexOf('$') === 0) {
            number = amount.replace('$', '');
        }

        if (!!el.value && (parseFloat(el.value) > parseFloat(number))) {
            return new ValidationError(el, {
                error: 'max_value',
                customErrorMessage,
                params: {
                    amount
                }
            });
        }

        return true;
    }

    /**
     * Validate - Minimum Value of a specific amount
     * @param el                    {HTMLElement}   Input being validated
     * @param amount                {int}           Min (Number or Money) Value
     * @param customErrorMessage    {string}        Overwrite Default Error Message
     * @return {*}
     */
    min_value(el, {
        amount,
        customErrorMessage = null
    } = {}) {
        let number = amount;
        if (amount.indexOf('$') === 0) {
            number = amount.replace('$', '');
        }

        if (!!el.value && (parseFloat(el.value) < parseFloat(number))) {
            return new ValidationError(el, {
                error: 'min_value',
                customErrorMessage,
                params: {
                    amount
                }
            });
        }

        return true;
    }

    /**
     * Validate - Confirm that Date has not yet passed
     * @param el                    {HTMLElement}   Input being validated
     * @param customErrorMessage    {string}        Overwrite Default Error Message
     * @return {*}
     */
    min_date_today(el, {
        customErrorMessage = null
    } = {}) {
        const inputtedDate = (!!el.value)
            ? moment(el.value)
            : moment();

        const isGreaterOrEqualTo = inputtedDate.isSameOrAfter(moment(), 'day');
        if (!isGreaterOrEqualTo) {
            return new ValidationError(el, {
                error: 'min_date_today',
                customErrorMessage
            });
        }

        return true;
    }

    /**
     * Validate - Alpha Dash
     * @param el                    {HTMLElement}   Input being validated
     * @param customErrorMessage    {string}        Overwrite Default Error Message
     * @return {*}
     */
    alpha_dash(el, {
        customErrorMessage = null
    } = {}) {
        if (!!el.value && !regexPatterns.alpha_dash.test(el.value)) {
            return new ValidationError(el, {
                error: 'alpha_dash',
                customErrorMessage
            });
        }

        return true;
    }

    /**
     * Validate - UTF-8 Four Byte Character
     * @param el                    {HTMLElement}   Input being validated
     * @param customErrorMessage    {string}        Overwrite Default Error Message
     * @return {*}
     */
    no_utf8mb4(el, {
        customErrorMessage = null
    } = {}) {
        if (!!el.value && regexPatterns.no_utf8mb4.test(el.value)) {
            return new ValidationError(el, {
                error: 'no_utf8mb4',
                customErrorMessage
            });
        }

        return true;
    }

    /**
     * Validate - Alpha Dash Space
     * @param el                    {HTMLElement}   Input being validated
     * @param customErrorMessage    {string}        Overwrite Default Error Message
     * @return {*}
     */
    alpha_dash_space(el, {
        customErrorMessage = null
    } = {}) {
        if (!!el.value && !regexPatterns.alpha_dash_space.test(el.value)) {
            return new ValidationError(el, {
                error: 'alpha_dash_space',
                customErrorMessage
            });
        }

        return true;
    }

    /**
     * Validate - Disallow Only Dashes and/or Underscore
     * @param el                    {HTMLElement}   Input being validated
     * @param customErrorMessage    {string}        Overwrite Default Error Message
     * @return {*}
     */
    not_only_dashes_underscore(el, {
        customErrorMessage = null
    } = {}) {
        if (!!el.value && regexPatterns.dash_underscore.test(el.value)) {
            return new ValidationError(el, {
                error: 'not_only_dashes_underscore',
                customErrorMessage
            });
        }

        return true;
    }

    /**
     * Validate - Is valid decimal number?
     * @param el                    {HTMLElement}   Input being validated
     * @param customErrorMessage    {string}        Overwrite Default Error Message
     * @return {*}
     */
    valid_decimal(el, {
        customErrorMessage = null
    } = {}) {
        if (!is_valid_decimal(el.value)) {
            return new ValidationError(el, {
                error: 'valid_decimal',
                customErrorMessage
            });
        }

        return true;
    }

    /**
     * Validate - Email
     * @param el                    {HTMLElement}   Input being validated
     * @param customErrorMessage    {string}        Overwrite Default Error Message
     * @return {*}
     */
    valid_website(el, {
        customErrorMessage = null
    } = {}) {
        if (!!el.value && !regexPatterns.website_single_field.test(el.value)) {
            return new ValidationError(el, {
                error: 'valid_website',
                customErrorMessage
            });
        }

        return true;
    }

    /**
     * Validate - Email
     * @param el                    {HTMLElement}   Input being validated
     * @param customErrorMessage    {string}        Overwrite Default Error Message
     * @return {*}
     */
    valid_email(el, {
        customErrorMessage = null
    } = {}) {
        if (!!el.value && !regexPatterns.email.test(el.value)) {
            return new ValidationError(el, {
                error: 'valid_email',
                customErrorMessage
            });
        }

        return true;
    }

    /**
     * Validate - Sanitizer Validation Function: Sanitizer.sanitize_phone_validation()
     * @param el                    {HTMLElement}   Input being validated
     * @param customErrorMessage    {string}        Overwrite Default Error Message
     * @return {*}
     */
    valid_phone(el, {
        customErrorMessage = null
    } = {}) {
        if (!!el.value && (!this.Sanitizer.sanitize_phone_validation(el.value))) {
            return new ValidationError(el, {
                error: 'valid_phone',
                customErrorMessage
            });
        }

        return true;
    }

    /**
     * Validate - State / Province (only mandatory for countries having states or provinces)
     * @param el                    {HTMLElement}   Input being validated
     * @param customErrorMessage    {string}        Overwrite Default Error Message
     * @return {*}
     */
    valid_state(el, {
        customErrorMessage = null
    } = {}) {
        if (!el.disabled && !el.value) {
            return new ValidationError(el, {
                error: 'valid_state',
                customErrorMessage
            });
        }

        return true;
    }

    /**
     * Validate - Sanitizer Validation Function: Sanitizer.sanitize_text_validation()
     * @param el                    {HTMLElement}   Input being validated
     * @param allow_trim            {boolean}       Allow whitespace to be trimmed from string?
     * @param allow_emojis          {boolean}       Allow emojis to be trimmed from string?
     * @param customErrorMessage    {string}        Overwrite Default Error Message
     * @return {*}
     */
    async sanitized_text(el, {
        allow_trim = true,
        allow_emojis = false,
        customErrorMessage = null
    } = {}) {
        // If the first characters are single or double quotes, display custom error
        if ((el.value.indexOf(`'`) === 0) || (el.value.indexOf(`"`) === 0)) {
            return new ValidationError(el, {
                error: 'sanitized_text_not_starting_with_quotes'
            });
        }

        const isNotEmpty = !!el.value;
        const sanitizedTextIsValid = isNotEmpty
            ? await this.Sanitizer.sanitize_text_validation(el.value, { allow_trim, allow_emojis })
            : false;
        if (isNotEmpty && !sanitizedTextIsValid) {
            return new ValidationError(el, {
                error: 'sanitized_text',
                customErrorMessage
            });
        }

        return true;
    }

    /**
     * Validate - Uppercase Letter
     * @param el                    {HTMLElement}   Input being validated
     * @param customErrorMessage    {string}        Overwrite Default Error Message
     * @return {*}
     */
    requires_uppercase(el, {
        customErrorMessage = null
    } = {}) {
        if (!!el.value && !regexPatterns.uppercase.test(el.value)) {
            return new ValidationError(el, {
                error: 'requires_uppercase',
                customErrorMessage
            });
        }

        return true;
    }

    /**
     * Validate - Lowercase Letter
     * @param el                    {HTMLElement}   Input being validated
     * @param customErrorMessage    {string}        Overwrite Default Error Message
     * @return {*}
     */
    requires_lowercase(el, {
        customErrorMessage = null
    } = {}) {
        if (!!el.value && !regexPatterns.lowercase.test(el.value)) {
            return new ValidationError(el, {
                error: 'requires_lowercase',
                customErrorMessage
            });
        }

        return true;
    }

    /**
     * Validate - Number
     * @param el                    {HTMLElement}   Input being validated
     * @param customErrorMessage    {string}        Overwrite Default Error Message
     * @return {*}
     */
    requires_number(el, {
        customErrorMessage = null
    } = {}) {
        if (!!el.value && !regexPatterns.number.test(el.value)) {
            return new ValidationError(el, {
                error: 'requires_number',
                customErrorMessage
            });
        }

        return true;
    }

    /**
     * Validate - Alphanumeric
     * @param el                    {HTMLElement}   Input being validated
     * @param customErrorMessage    {string}        Overwrite Default Error Message
     * @return {*}
     */
    requires_alphanumeric(el,{
        customErrorMessage = null
    } = {}){
        if (!!el.value && !regexPatterns.alphanumeric.test(el.value)) {
            return new ValidationError(el, {
                error: 'requires_alphanumeric',
                customErrorMessage
            });
        }

        return true;
    }

    /**
     * Validate - Special Character
     * @param el                    {HTMLElement}   Input being validated
     * @param customErrorMessage    {string}        Overwrite Default Error Message
     * @return {*}
     */
    requires_special_character(el, {
        customErrorMessage = null
    } = {}) {
        if (!!el.value && !regexPatterns.special_character.test(el.value)) {
            return new ValidationError(el, {
                error: 'requires_special_character',
                customErrorMessage
            });
        }

        return true;
    }

    /**
     * Validate - Sanitizer Validation Function: Sanitizer.sanitize_emoji_validation()
     * @param el                    {HTMLElement}   Input being validated
     * @param customErrorMessage    {string}        Overwrite Default Error Message
     * @return {*}
     */
    no_emojis(el, {
        customErrorMessage = null
    } = {}) {
        if (!!el.value && (!this.Sanitizer.sanitize_emoji_validation(el.value))) {
            return new ValidationError(el, {
                error: 'no_emojis',
                customErrorMessage
            });
        }

        return true;
    }


    /**
     * Validate - Sanitizer Validation Function: Sanitizer.sanitize_alt_code_validation()
     * @param el                    {HTMLElement}   Input being validated
     * @param customErrorMessage    {string}        Overwrite Default Error Message
     * @return {*}
     */
    no_alt_codes(el, {
        customErrorMessage = null
    } = {}) {
        if (!!el.value && (!this.Sanitizer.sanitize_alt_code_validation(el.value))) {
            return new ValidationError(el, {
                error: 'no_alt_codes',
                customErrorMessage
            });
        }

        return true;
    }

    /**
     * Validate - Username
     * @param el                    {HTMLElement}   Input being validated
     * @param customErrorMessage    {string}        Overwrite Default Error Message
     * @return {*}
     */
    valid_username(el, {
        customErrorMessage = null
    } = {}) {
        if (!!el.value && !regexPatterns.username.test(el.value)) {
            return new ValidationError(el, {
                error: 'valid_username',
                customErrorMessage
            });
        }

        return true;
    }

    /**
     * Validate - MemberViolation Checks:
     * - Can not contain email
     * - Can not contain phone number
     * - Can not contain website
     * @param el                    {HTMLElement}   Input being validated
     * @param ignore                {array}         List of Member Violation rules to ignore. Options: 'email', 'phone', 'website'
     * @param customErrorMessage    {string}        Overwrite Default Error Message
     * @return {*}
     */
    check_member_violations(el, {
        ignore = [],
        customErrorMessage = null
    } = {}) {
        // Can not contain email addresses
        let containsEmail = false;
        if (!ignore.includes('email')) {
            containsEmail = (!!el.value && regexPatterns.email.test(el.value));
        }

        // Can not contain phone numbers
        let containsPhone = false;
        if (!ignore.includes('phone')) {
            containsPhone = (!!el.value && regexPatterns.phone.test(el.value));
        }

        // Can not contain any website (except for voices.com website)
        let containsWebsite = false;
        if (!ignore.includes('website')) {
            // Get all websites matches
            const websiteMatches = el.value.match(regexPatterns.website);
            let validateWebsites = [];

            // If any website matches, compare against voices.com website.
            if (websiteMatches) {
                websiteMatches.forEach(str => {
                    validateWebsites.push(regexPatterns.voices.test(str));
                });
            }

            // If even one match is bad (not voices.com) the input is considered invalid
            containsWebsite = validateWebsites.some(e => e === false);
        }

        if (containsEmail || containsPhone || containsWebsite) {
            return new ValidationError(el, {
                error: 'check_member_violations',
                customErrorMessage,
                params: {
                    ignore
                }
            });
        }

        return true;
    }

    /**
     * Validate - Check if input value is an exact match to the value of another Input
     * @param currentElement        {HTMLElement}   Input being validated
     * @param element               {HTMLElement}   Input Element this Input must be validated with
     * @param customErrorMessage    {string}        Overwrite Default Error Message
     * @return {*}
     */
    matches_value(currentElement, {
        element: otherElement = null,
        customErrorMessage = null
    } = {}) {
        // Other element doesn't exist on DOM? We will assume this field to be valid then, since we have nothing to compare to
        if (!otherElement) {
            return true;
        }

        const otherElementValueIsMatched = currentElement.value === otherElement.value;

        if (!otherElementValueIsMatched) {
            return new ValidationError(currentElement, {
                error: 'matches_value',
                customErrorMessage,
                params: {
                    otherElementName: otherElement.getAttribute('data-display-name') || otherElement.name
                }
            });
        }

        return true;
    }

    /**
     * Validate - Check if input value is a valid Credit Card Number
     * @param currentElement        {HTMLElement}   Input being validated
     * @param el                    {HTMLElement}   Input Element this Input must be validated with
     * @param customErrorMessage    {string}        Overwrite Default Error Message
     * @return {*}
     */
    valid_credit_card_number(el, {
        customErrorMessage = null
    } = {}) {

        if (el.value) {
            const valid = CreditCardValidatorHelper.isCardNumberValid(el.value);

            if (!valid) {
                return new ValidationError(el, {
                    error: 'valid_credit_card_number',
                    customErrorMessage
                });
            }

            const isAccepted = CreditCardValidatorHelper.isCreditCardAccepted(el.value);

            if (!isAccepted) {
                const cardName = CreditCardValidatorHelper.getCreditCardNameByNumber(el.value);
                return new ValidationError(el, {
                    error: 'accepted_credit_card',
                    customErrorMessage,
                    params: {
                        cardName
                    }
                });
            }

            return true;

        } else {
            return new ValidationError(el, {
                error: 'valid_credit_card_number',
                customErrorMessage
            });
        }
    }

    /**
     * Validate - Check if input value is a valid Credit Card Expiration Date
     * @param currentElement        {HTMLElement}   Input being validated
     * @param el                    {HTMLElement}   Input Element this Input must be validated with
     * @param customErrorMessage    {string}        Overwrite Default Error Message
     * @return {*}
     */
    valid_credit_card_expiration(el, {
        customErrorMessage = null
    } = {}) {

        if (el.value) {
            const splitValue = el.value.split('/');
            const {
                      month = parseInt(splitValue[0]),
                      year  = parseInt(splitValue[1])
                  } = splitValue;

            const isValidMonth = CreditCardValidatorHelper.isValidMonth(month);
            const isValidYear = CreditCardValidatorHelper.isValidYear(year);
            const isFutureOrPresentDate = CreditCardValidatorHelper.isFutureOrPresentDate(month, year);

            if (!isValidMonth) {
                return new ValidationError(el, {
                    error: 'valid_credit_card_expiration_month',
                    customErrorMessage
                });
            } else if (!isValidYear) {
                return new ValidationError(el, {
                    error: 'valid_credit_card_expiration_year',
                    customErrorMessage
                });
            } else if (!isFutureOrPresentDate) {
                return new ValidationError(el, {
                    error: 'valid_credit_card_expiration',
                    customErrorMessage
                });
            }

            return true;
        }
    }

    /**
     * Validate - Check if input value is a valid Credit Card Security Code
     * @param currentElement        {HTMLElement}   Input being validated
     * @param el                    {HTMLElement}   Input Element this Input must be validated with
     * @param customErrorMessage    {string}        Overwrite Default Error Message
     * @return {*}
     */
    valid_credit_card_security_code(el, {
        credit_card_number_el: creditCardNumberEl = null,
        customErrorMessage = null
    } = {}) {

        if (!creditCardNumberEl.value) {
            return new ValidationError(el, {
                error: 'valid_credit_card_security_code',
                customErrorMessage: 'Please Add Card Number First'
            });
        }

        if (creditCardNumberEl.value || el.value) {
            let valid = CreditCardValidatorHelper.isSecurityCodeValid(creditCardNumberEl.value, el.value);

            if (!valid) {
                return new ValidationError(el, {
                    error: 'valid_credit_card_security_code',
                    customErrorMessage
                });
            }

            return true;

        } else {
            return new ValidationError(el, {
                error: 'valid_credit_card_security_code',
                customErrorMessage
            });
        }
    }

    /**
     * Validate "M dd, yy" Date Format
     * @param el
     * @param customErrorMessage
     * @returns {ValidationError|boolean}
     */
    valid_date_format(el, {
        customErrorMessage = null
    } = {}) {
        if (!!el.value && !regexPatterns.date_format_standard.test(el.value)) {
            return new ValidationError(el, {
                error: 'valid_date_format',
                customErrorMessage
            });
        }

        return true;
    }

    /**
     * Validate "MM/DD/YYYY" OR "MM-DD-YYYY" OR "MM.DD.YYYY" Date Format
     * @param el
     * @param customErrorMessage
     * @returns {ValidationError|boolean}
     */
    valid_date_format_mmddyyyy(el, {
        customErrorMessage = null
    } = {}) {
        if (!!el.value && !regexPatterns.date_format_mmddyyyy.test(el.value)) {
            return new ValidationError(el, {
                error: 'valid_date_format_mmddyyyy',
                customErrorMessage
            });
        }

        return true;
    }

    /**
     * Validate "YYYY/MM/DD" OR "YYYY-MM-DD" OR "YYYY.MM.DD" Date Format
     * @param el
     * @param customErrorMessage
     * @returns {ValidationError|boolean}
     */
    valid_date_format_yyyymmdd(el, {
        customErrorMessage = null
    } = {}) {
        if (!!el.value && !regexPatterns.date_format_yyyymmdd.test(el.value)) {
            return new ValidationError(el, {
                error: 'valid_date_format_yyyymmdd',
                customErrorMessage
            });
        }

        return true;
    }

    /**
     * Validate via AJAX
     * @param el                    {HTMLElement}   Input Field to validate against
     * @param url                   {string}        Ajax Request URL
     * @param onSuccess             {function}      Function to execute on successful fetch request
     * @param customErrorMessage    {string}        Overwrite Default Error Message
     * @return {*}
     */
    async ajax_validation(el, {
        url = null,
        onSuccess = (response) => true,
        customErrorMessage = null
    } = {}) {
        if (!url) {
            console.error('Failed to validate input, missing URL parameter for AJAX Request');
            return;
        }

        if (isEmpty(el)) {
            return new ValidationError(el, {
                error: 'required',
                customErrorMessage
            });
        }

        // Send Fetch Request to validate this input's value....
        // Possible use case scenarios:
        // - validate uniqueness
        // - validate data matching (organization to member relationship matches?)
        // - etc
        return await fetch(`${url}?${el.name}=${encodeURIComponent(el.value)}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Fetch Request Failed');
                }

                return response.json();
            })
            .then(response => {
                if (response.error) {
                    throw new Error(response.error);
                }

                // Return Successful Response
                return onSuccess(response);
            })
            .catch(err => {
                return new ValidationError(el, {
                    error: 'ajax_validation',
                    customErrorMessage
                });
            });
    }

}

module.exports = ValidationFunctions;
