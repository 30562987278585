/**
 * Verify is Element is Text Element
 * @param el
 * @return {boolean}
 */
const isTextElement = el => {
    const inputType = el.type || null;
    const tagName = el.tagName.toLowerCase();

    return (
        (
            tagName === 'input'
            || tagName === 'textarea'
        )
        && (
            inputType !== 'checkbox'
            && inputType !== 'radio'
            && inputType !== 'file'
        )
    );
};

/**
 * Verify is Element is Selector Element
 * @param el
 * @return {boolean}
 */
const isSelectableElement = el => {
    const tagName = el.tagName.toLowerCase();
    return tagName === 'select'
};

/**
 * Verify is Element is Checkbox or RadioButton Element
 * @param el
 * @return {boolean}
 */
const isCheckableElement = el => {
    const inputType = el.type || null;
    const tagName = el.tagName.toLowerCase();

    return (
        tagName === 'input'
        && (
            inputType === 'checkbox'
            || inputType === 'radio'
        )
    );
};

/**
 * Check if input is empty
 * @param el
 * @return {boolean}
 */
const isEmpty = el => {
    const emptyText = (
        isTextElement(el)
        && !el.value.trim()
    );

    const emptySelection = (
        isSelectableElement(el)
        && !el.value.trim()
    );

    const emptyCheckboxOrRadio = (
        isCheckableElement(el)
        && !document.querySelector(`[name="${el.name}"]:checked`)
    );

    const emptyFileInput = (
        el.type === 'file'
        && el.files.length === 0
    )

    return (emptyText || emptySelection || emptyCheckboxOrRadio || emptyFileInput);
};

module.exports = {
    isTextElement,
    isSelectableElement,
    isCheckableElement,
    isEmpty
};
