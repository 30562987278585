const ScrollTo = require('../ScrollTo');
const ValidationError = require('./ValidationError');
const ValidationFunctions = require('./ValidationFunctions');
const SanitizationFunctions = require('../Sanitizer');
const { isTextElement } = require('./utilities'); // Validator utilities
const { debounce } = require('../Utilities'); // global utilities

/**
 * Utility Function: Insert HTMLElement after reference Node.
 * @param el
 * @param referenceNode
 */
function insertAfter(el, referenceNode) {
    referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
}

/**
 * @class Validator
 * @classdesc Voices.com custom Validator Library. https://voices.atlassian.net/wiki/spaces/TEC/pages/494174221/Frontend-Form-Validation-Javascript-Library
 */
class Validator {

    /**
     * Object containing Input properties (for a specific Input being plugged into Validator)
     * @typedef  {Object} ValidatableInput
     * @property {string} label                         Input Label
     * @property {array}  rules                         Input Validation Rules
     * @property {ValidatableInputError} error          Input Error (customization)
     */

    /**
     * Object containing Input Error properties (for a specific Input being plugged into Validator)
     * @typedef  {Object} ValidatableInputError
     * @property {HTMLElement} insertAfter              Insert error element after this reference element
     * @property {boolean} disableErrorStyling          Disable restyling for input error
     * @property {object}  customDataAttributes         Custom data-attributes to apply on input error element
     * @property {object}  messages                     Custom error messages based on the specific rules that were defined for this ValidatableInput
     * @property {boolean} preventErrorDisplay          Prevent Errors from being displayed (for all validation rule errors)
     */

    /**
     * Validator Class Constructor
     * @constructor
     * @param {HTMLElement} form                                Form element to mount validator onto
     * @param {object.<ValidatableInput>} inputs                Collection of inputs, that contain validation rules & other config settings
     * @param {array} submitButtons                             Optional. Provide custom list of Submit Buttons. If left empty, buttons will be programatically identified based on [type="submit"] attribute
     * @param {object} customValidationFunctions                Custom ValidationFunctions to inject into the Validator
     * @param {boolean} allowTrimOnAllTextFields                Automatically attach `trim` sanitization rule on all text fields
     * @param {boolean} allowScroll                             Scroll to first invalid input on form validation
     * @param {boolean} allowInputValidationOnChange            Allow Input Validation when user makes a change to the input
     * @param {boolean} allowSubmitButtonProcessing             When form is valid, and submit button is clicked, display "Processing..." text
     * @param {boolean} allowSubmitButtonDisabling              Disable submit buttons until form fields are filled out (not necessarily valid)
     * @param {boolean} displayErrorsAfterEvaluateSubmitDisablingInvokedOnLoad  Display errors after initial evaluateSubmitDisabling() call has been made
     * @param {boolean} validateCustomRulesForButtonEnabling    Validate custom rules before enabling buttons. Default: false
     * @param {array} customRulesForButtonEnabling              Custom Rules to Validate before enabling buttons. Default: 'required', 'required_when', 'required_as_group', 'minlength'
     * @param {function} onAfterEvaluateSubmitDisabling         Handle anything after evaluateSubmitDisabling() function is called.
     * @param {function|null} onBeforeValidation                Handle anything before validating the form?
     * @param {function|null} onAfterValidation                 Handle anything after validating the form?
     * @param {function|null} onBeforeErrorDisplay              Handle anything before displaying errors?
     * @param {function|null} onBeforeSubmit                    Handle anything before submitting the form?
     * @param {function|null} onSubmit                          Custom function to run instead of default form.submit()
     * @param {function|null} onAfterSubmit                     TODO.
     * @param {function} onFailedSubmit                         Custom function to run on submission failure
     * @param {function|null} filterErrorMessage                Override error message based on form state if necessary
     * @param {array} buttonsToDisableWhenProcessing            List of HTMLElement buttons that will become
     * @param {string} submitButtonProcessingText               Customize "Processing..." text
     * @param {string} inputErrorClass                          Class name to apply to <input> elements when an error was caught
     * @param {string} inputNotificationClass                   Class name to apply to generated <div> message relating to the input
     * @param {string} inputErrorNotificationClass              Class name to apply to generated <div> error message relating to the input
     **/
    constructor(form = null, {
        inputs = {},
        submitButtons = [],
        customValidationFunctions = {},
        allowTrimOnAllTextFields = true,
        allowScroll = true,
        allowInputValidationOnChange = true,
        allowSubmitButtonProcessing = true,
        allowSubmitButtonDisabling = false,
        displayErrorsAfterEvaluateSubmitDisablingInvokedOnLoad = false,
        validateCustomRulesForButtonEnabling = false,
        customRulesForButtonEnabling = ['required', 'required_when', 'required_as_group', 'maxlength', 'minlength', 'max_value', 'min_value', 'custom'],
        onAfterEvaluateSubmitDisabling = (valid) => {},
        onBeforeValidation = null,
        onAfterValidation = null,
        onBeforeErrorDisplay = null,
        onBeforeSubmit = null,
        onSubmit = null,
        onAfterSubmit = null,
        onFailedSubmit = (err) => {},
        filterErrorMessage = null,
        buttonsToDisableWhenProcessing = [],
        submitButtonProcessingText = 'Processing...',
        inputErrorClass = 'form-input-error',
        inputNotificationClass = 'form-input-message',
        inputErrorNotificationClass = 'form-input-message-error'
    } = {}) {
        if (!form) {
            throw new Error('Validator must be assigned to a valid form HTMLElement.');
        }

        // Config
        this.config = {
            allowTrimOnAllTextFields,
            allowScroll,
            allowInputValidationOnChange,
            allowSubmitButtonProcessing,
            allowSubmitButtonDisabling,
            displayErrorsAfterEvaluateSubmitDisablingInvokedOnLoad,
            validateCustomRulesForButtonEnabling,
            customRulesForButtonEnabling,
            excludeInputTypesFromValidation: ['button', 'submit', 'hidden'],
            submitButtonProcessingText,
            inputErrorClass,
            inputNotificationClass,
            inputErrorNotificationClass
        };

        // Define Globals
        this.form = form;

        this.submitBtns = submitButtons.length === 0
            ? Array.prototype.slice.call(
                this.form.querySelectorAll('[type="submit"]')
            )
            : submitButtons.filter(submitBtn => !!submitBtn); // filter out any elements that do not exist on the DOM

        if (this.submitBtns.length === 0) {
            throw new Error('Validator requires the form HTMLElement to contain a submit button.');
        }

        // Filter out non-existing HTMLElements. Must be a valid button.
        this.buttonsToDisableWhenProcessing = buttonsToDisableWhenProcessing;

        // Disable submit button until all 'required' inputs are filled out
        if (this.config.allowSubmitButtonDisabling) {
            this.disableSubmitButtons();
        }

        this.inputNamesWithForcedValidity = [];

        // Validator State Handling
        this.state = {
            isSubmitting: false
        };

        // Input Properties that were passed into the Validator
        this.validationRules = this._reduceDataObject(inputs, 'rules');
        this._initialValidationRules = this.validationRules;

        this.validationInputLabels = this._reduceDataObject(inputs, 'label');
        this._initialValidationInputLabels = this.validationInputLabels;

        this.relatedElementsConfig = this._reduceDataObject(inputs, 'relatedElements');
        this._initialRelatedElementsConfig = this.relatedElementsConfig;

        this.customErrorConfig = this._reduceDataObject(inputs, 'error');
        this._initialCustomErrorConfig = this.customErrorConfig;

        this.hiddenInputNames = this._reduceDataArray(inputs, 'hidden');
        this._initialHiddenInputNames = this.hiddenInputNames;

        // Map Form Elements & Initial Validation Errors
        this.remapFormElements(true);

        // ValidationFunctions to use against the validation rules
        this.fn = new ValidationFunctions();

        // SanitizationFunctions
        this.sanitizationFn = new SanitizationFunctions();

        // Initialize the validation errors
        this.validationErrors = [];

        // Bind customValidationFunctions to ValidationFunctions collection
        Object.keys(customValidationFunctions).forEach(fnName => {
            this.fn[fnName] = customValidationFunctions[fnName];
        });

        // Define Callback Function
        this.onAfterEvaluateSubmitDisabling = (valid) => onAfterEvaluateSubmitDisabling(valid);

        this.onBeforeValidation = (typeof onBeforeValidation === 'function')
            ? () => onBeforeValidation()
            : () => this._onBeforeValidation();

        this.onAfterValidation = (typeof onAfterValidation === 'function')
            ? (validationErrors, valid) => onAfterValidation(validationErrors, valid)
            : (validationErrors, valid) => this._onAfterValidation(validationErrors, valid);

        this.onBeforeErrorDisplay = (typeof onBeforeErrorDisplay === 'function')
            ? (el, rule) => onBeforeErrorDisplay(el, rule)
            : (el, rule) => this._onBeforeErrorDisplay(el, rule);

        this.filterErrorMessage = (typeof filterErrorMessage === 'function')
            ? (inputName, errorMessage) => filterErrorMessage(inputName, errorMessage)
            : (inputName, errorMessage) => this._filterErrorMessage(inputName, errorMessage)

        // Attach Submit Events
        this.onBeforeSubmit = (typeof onBeforeSubmit === 'function')
            ? () => onBeforeSubmit()
            : () => this._onBeforeSubmit();

        this.onSubmit = (typeof onSubmit === 'function')
            ? e => onSubmit(e)
            : e => this._onSubmitDefault(e);

        this.onAfterSubmit = (typeof onAfterSubmit === 'function')
            ? () => onAfterSubmit()
            : () => this._onAfterSubmit();

        this._callbackEvents = {
            onFailedSubmit
        }

        // Enable Submit by attaching Event Listeners
        this._attachEventListenersToSubmitButtons();

        // Async/Await Events: Onload
        (async () => {
            if (this.config.allowSubmitButtonDisabling) {
                await this.evaluateSubmitDisabling(this.config.displayErrorsAfterEvaluateSubmitDisablingInvokedOnLoad);
            }
        })();
    }

    /**
     * Build Data Object
     * @param targetDataObject      Target Object, which will be getting reduced based on the provided property name
     * @param property
     * @return {Object}
     * @private
     */
    _reduceDataObject(targetDataObject, property) {
        return Object.keys(targetDataObject).reduce((results, inputName) => {
            const {
                [inputName]: {
                    [property]: value = null
                }
            } = targetDataObject;

            // fix bug where inputName is coming through as a number, and therefore building the data objects with stuff like this:
            // { "0": null, "1": null, "2": null }
            const isInvalidEntry = (!isNaN(Number(inputName)) && value === null);
            if (isInvalidEntry) {
                return results;
            }

            return {
                ...results,
                [inputName]: value
            };
        }, {});
    }

    /**
     * Build Data Array
     * @param targetDataObject      Target Object, which will be getting reduced based on the provided property name
     * @param property
     * @return {Array}
     * @private
     */
    _reduceDataArray(targetDataObject, property) {
        return Object.keys(targetDataObject).reduce((results, inputName) => {
            const {
                [inputName]: {
                    [property]: value = null
                }
            } = targetDataObject;

            if (!value) {
                return results;
            }

            return [
                ...results,
                inputName
            ];
        }, []);
    }

    /**
     * Modify error message if necessary for the form state
     * @private
     * @param errorMessage
     * @return {array}        Return Type MUST be an array with a [rule,message] pairing
     */
    _filterErrorMessage(inputName, errorMessage){
        return errorMessage;
    }

    /**
     * Events to run before displaying an error
     * @private
     * @param el
     * @param rule
     * @return {boolean}        Return Type MUST be a Boolean!
     */
    _onBeforeErrorDisplay(el, rule) {
        return true;
    }

    /**
     * Events to run before validating form
     * @private
     * @return {boolean}        Return Type MUST be a Boolean! In order to proceed with onBeforeSubmit(), onSubmit() or onFailedSubmit(), need to know how this function worked...
     */
    _onBeforeValidation() {
        return true;
    }

    /**
     * Events to run after validating form. These do not affect the form's submission state.
     * @private
     * @return void
     */
    _onAfterValidation(validationErrors, valid) {
        return;
    }

    /**
     * Events to run before submitting form
     * @private
     * @return {boolean}        Return Type MUST be a Boolean! In order to proceed with onSubmit() or onFailedSubmit(), need to know how this function worked...
     */
    _onBeforeSubmit() {
        return true;
    }

    /**
     * Submit Form using default form.submit() method
     * @param event
     * @private
     */
    _onSubmitDefault(event) {
        // Append submit button's name:value to Form Data
        const submitter = event.target;
        if (submitter && submitter.getAttribute('name')) {
            this._appendSubmitButtonToFormDataWhenValidated(submitter);
        }

        // Submit
        // Note: Can not just use `this.form.submit()` in case a form element is named "submit"
        HTMLFormElement.prototype.submit.call(this.form);
    }

    /**
     * Events to run after submitting form
     */
    _onAfterSubmit() {
        // TODO?
    }

    /**
     * Failed to Submit Form
     * @param err       {Error}
     * @private
     */
    _onFailedSubmit(err) {
        if (err instanceof Error) {
            console.error(err);
        }

        const { onFailedSubmit = (err) => {} } = this._callbackEvents;
        onFailedSubmit(err);
    }

    /**
     * Attach Event Listeners to submit button(s)
     * @private
     */
    _attachEventListenersToSubmitButtons() {
        this.submitBtns.forEach(submitBtn => this._attachEventListenersToSubmitButton(submitBtn));
    }

    /**
     * Attach Event Listeners to submit button
     * @param submitBtn                 {HTMLElement}
     * @param preventBeforeSubmit       {bool}
     * @param preventAfterSubmit        {bool}
     * @private
     */
    _attachEventListenersToSubmitButton(submitBtn, {
        preventBeforeSubmit = false,
        preventAfterSubmit = false
    } = {}) {
        const defaultSubmitBtnText = submitBtn.innerHTML;

        // Disable Submit Button
        const disableSubmitButton = () => this.disableSubmitButton(submitBtn);

        // Enable Submit Button
        const enableSubmitButton = () => this.enableSubmitButton({
            submitBtn,
            submitBtnText: defaultSubmitBtnText
        });

        submitBtn.addEventListener('click', async event => {
            event.preventDefault();

            // Begin processing request... disable the form's submit button
            disableSubmitButton();

            // Form is currently submitting. Stop here.
            if (this.state.isSubmitting) {
                return true;
            }

            // Begin submitting.
            this.state.isSubmitting = true;

            // Validate form
            const valid = await this.validateForm();
            if (valid) {
                await new Promise(async (resolve, reject) => {
                    if (preventBeforeSubmit) {
                        resolve(); // Skip running the BeforeSubmit method. Continue to Submit...
                    }
                    else {
                        const ok = await this.onBeforeSubmit();
                        if (ok) {
                            resolve();
                        }
                        else {
                            reject();
                        }
                    }
                })
                    .then(async () => {
                        await this.onSubmit(event);
                        this.state.isSubmitting = false;
                    })
                    .then(async () => {
                        if (preventAfterSubmit) {
                            return;
                        }
                        await this.onAfterSubmit();
                    })
                    .catch(err => {
                        enableSubmitButton();
                        this._onFailedSubmit(err);
                        enableSubmitButton();
                        this.state.isSubmitting = false;
                    });
            }
            else {
                this.state.isSubmitting = false;
                enableSubmitButton();
            }

            return valid;
        });
    }

    /**
     * Attach Event Listeners to a single Input Field
     * @param el
     * @private
     */
    _attachEventListenersToInput(el) {
        if (
            // element type is not excluded
            !this.config.excludeInputTypesFromValidation.includes(el.type)
            // or element type is hidden, but was mapped as a hidden input in the Validator
            || (
                this.config.excludeInputTypesFromValidation.includes('hidden')
                && el.type === 'hidden'
                && this.hiddenInputNames.includes(el.name)
            )
        ) {
            const isDatepicker = typeof el.datepicker === 'object' && el.datepicker !== null;

            if (isDatepicker) {
                el.addEventListener('changeDate', async () => await this.validateInput(el));
            } else {
                el.addEventListener('change', async () => await this.validateInput(el));
            }

            // All specified validation rules must pass in order for Submit Buttons to become enabled.
            if (this.config.allowSubmitButtonDisabling) {
                el.addEventListener('change', async () => await this.evaluateSubmitDisabling());
                el.addEventListener('keyup', debounce(async () => await this.evaluateSubmitDisabling(), 250));
            }
        }
    }

    /**
     * Append new Submit Button HTMLElement to the form
     *
     * Example: A modal has to handle submitting the form... (See Talent's Edit Profile page)
     *
     * @param submitBtn                 {HTMLElement}
     * @param preventBeforeSubmit       {bool}
     * @param preventAfterSubmit        {bool}
     */
    appendSubmitButtonToForm(submitBtn, {
        preventBeforeSubmit = false,
        preventAfterSubmit = false
    } = {}) {
        this.submitBtns.push(submitBtn);

        this._attachEventListenersToSubmitButton(submitBtn, {
            preventBeforeSubmit,
            preventAfterSubmit
        });
    }

    /**
     * Disable all submit type buttons within the form
     */
    disableSubmitButtons() {
        this.submitBtns.forEach(submitBtn => submitBtn.disabled = true);
    }

    /**
     * Disable all buttons set with buttonsToDisableWhenProcessing within the form
     */
    disableAllButtons() {
        const buttonsToDisable = [...this.submitBtns, ...this.buttonsToDisableWhenProcessing];
        buttonsToDisable.forEach(btn => btn.disabled = true);
    }

    /**
     * Enable all submit type buttons within the form
     */
    enableSubmitButtons() {
        this.submitBtns.forEach(submitBtn => {
            if (submitBtn.dataset.originalContent) submitBtn.innerHTML = submitBtn.dataset.originalContent;
            submitBtn.disabled = false;
        });
    }

    /**
     * Enable all buttons set with buttonsToDisableWhenProcessing within the form
     */
    enableAllButtons() {
        const buttonsToEnable = [...this.submitBtns, ...this.buttonsToDisableWhenProcessing];
        buttonsToEnable.forEach(btn => {
            if (btn) {
                if (btn.dataset.originalContent) btn.innerHTML = btn.dataset.originalContent;
                btn.disabled = false;
            }
        });
    }

    /**
     * Remap Form Elements, and Validation Errors
     * @param initialState  {boolean}   Remaps Form as initial state? (No errors, for example)
     */
    remapFormElements(initialState = false) {
        let formElements = [];

        // NodeList to Array
        if (this.form.elements) {
            formElements = Array.prototype.slice.call(this.form.elements);
        }

        // <form> is not the actual tag name for the provided `this.form`, so the inner elements must be found from another way...
        if (formElements.length === 0) {
            formElements = [
                ...this.form.getElementsByTagName('input'),
                ...this.form.getElementsByTagName('select'),
                ...this.form.getElementsByTagName('textarea')
            ];
        }

        // Filter out elements that can not be validated
        formElements = formElements.filter(el => {
            if (
                (
                    // element type is not excluded
                    !this.config.excludeInputTypesFromValidation.includes(el.type)
                    // or element type is hidden, but was mapped as a hidden input in the Validator
                    || (
                        this.config.excludeInputTypesFromValidation.includes('hidden')
                        && el.type === 'hidden'
                        && this.hiddenInputNames.includes(el.name)
                    )
                )
                // name is not empty
                && !!el.name
                && (el.name.replace('[]','') in this.validationRules) //For multi-select radios, make sure we strip out the []
            ) {
                return el;
            }
        });

        // Check again that there are form elements which can be validated.
        // If not, there is no point in continuing, so we will stop here.
        if (formElements.length === 0) {
            this.formElements = [];
            return;
        }

        // Assign global formElements array, now that we know there is data there.
        this.formElements = formElements;

        // Populate this array with all instances of input elements, even if they are not unique instances
        const formElementNamesCollection = [];

        // Populate this object by input elements grouped by name
        const formElementGroupsCollection = {};

        this.formElementCollection = this.formElements.reduce((formElementCollection, el) => {
            let name = el.name;

            // Contains values like... -------------------------------  Outputs like...
            // <input type="checkbox" name="accent_ids[]" />            ==>  "accent_ids"
            // <input type="text" name="testimonials[0][comments]" />   ==>  "testimonials[comments]"
            const arrayInputRegex = new RegExp(/\[(?:\d)?\]/, 'gi');
            if (arrayInputRegex.test(el.name)) {
                name = el.name.replace(arrayInputRegex, '');
            }

            // Append to Names Collection (even though instance may not be unique)
            formElementNamesCollection.push(name);

            // Append to Groups Collection
            if (!formElementGroupsCollection.hasOwnProperty(name)) {
                formElementGroupsCollection[name] = [];
            }
            formElementGroupsCollection[name].push(el);

            // Get Index & Group based on current number of instances of this name in the collection
            const group = formElementGroupsCollection[name];
            const groupName = (formElementNamesCollection.filter(collectedName => name === collectedName));
            const index = groupName.length - 1;

            // Test whether this input is a multidimensional grouping...
            // ie- <input type="text" name="testimonials[0][comments]" />
            const multidimensionalArrayInputRegex = new RegExp(/\[([a-z]+)\]/, 'gi');

            // Get Custom Label Text
            const {
                [name]: label = null
            } = this.validationInputLabels;

            // Get Related Elements Config
            const {
                [name]: relatedElements = null
            } = this.relatedElementsConfig;

            // Get Custom Error Config
            const {
                [name]: errorConfig = null
            } = this.customErrorConfig;

            // Attach `data-display-name` attribute if an associated label value exists for this input
            if (!el.getAttribute('data-display-name') && label !== null) {
                el.setAttribute('data-display-name', label);
            }

            // Define element type if need be?
            const getFormElementType = (el) => {
                // Verify if date-picker type.
                if (el.classList.contains('date-picker') || el.classList.contains('datepicker-input')) {
                    return 'date-picker';
                }

                // Other logic checks here.
                // ...

                // Otherwise, use default `type` attribute
                return el.type;
            };

            return [
                ...formElementCollection,
                {
                    originalName: el.name,
                    name,
                    label,
                    type: getFormElementType(el),
                    index,
                    group,
                    multidimensional: multidimensionalArrayInputRegex.test(el.name),
                    errorConfig,
                    relatedElements
                }
            ];

        }, []);

        this.formElementNames = this.formElementCollection.reduce((formElementNames, formElement) => {
            if (formElementNames.includes(formElement.name)) {
                return formElementNames;
            }

            return [
                ...formElementNames,
                formElement.name
            ];
        }, []);

        // Get Validation Errors that are mapped to all our FormElementCollection
        this.validationErrors = this._mapValidationErrors(initialState);

        // Attach Event Listeners on all the remapped Form Elements
        if (this.config.allowInputValidationOnChange) {
            this.formElements.forEach(el => this._attachEventListenersToInput(el));
        }
    }

    /**
     * Reset Current Validator Instance
     */
    reset() {
        // Reset Validation Errors
        this.validationErrors = [];

        // Reset Validation Rules
        this.validationRules = this._initialValidationRules;
        this.validationInputLabels = this._initialValidationInputLabels;
        this.hiddenInputNames = this._initialHiddenInputNames;
        this.relatedElementsConfig = this._initialRelatedElementsConfig;
        this.customErrorConfig = this._initialCustomErrorConfig;
        this.remapFormElements(true);

        // Remove Error Messages from DOM
        if (this.formElements) {
            this.formElements.forEach(el => this.clearErrorsForInput(el));
        }
    }

    /**
     * Destroy Current Validator Instance
     */
    destroy() {
        // TODO? If we need it
    }

    /**
     * Add Validation Rules to current Validator Instance
     * @param validationRulesToAdd
     * @param evaluate
     */
    addValidationRules(validationRulesToAdd, { evaluate = true } = {}) {
        new Promise(async resolve => await resolve(
            this.addValidationRulesAsync(validationRulesToAdd, { evaluate })
        ));
    }

    /**
     * (Asynchronous) Add Validation Rules to current Validator Instance
     * @param validationRulesToAdd
     * @param evaluate
     */
    async addValidationRulesAsync(validationRulesToAdd, { evaluate = true } = {}) {
        if (Object.keys(validationRulesToAdd).length > 0) {
            this.validationRules = {
                ...this.validationRules,
                ...this._reduceDataObject(validationRulesToAdd, 'rules')
            };

            this.validationInputLabels = {
                ...this.validationInputLabels,
                ...this._reduceDataObject(validationRulesToAdd, 'label')
            };

            this.relatedElementsConfig = {
                ...this.relatedElementsConfig,
                ...this._reduceDataObject(validationRulesToAdd, 'relatedElements')
            };

            this.customErrorConfig = {
                ...this.customErrorConfig,
                ...this._reduceDataObject(validationRulesToAdd, 'error')
            };

            this.hiddenInputNames = [
                ...this.hiddenInputNames,
                ...this._reduceDataArray(validationRulesToAdd, 'hidden')
            ];
        }

        this.remapFormElements();

        // We've added new rules. Check to see if we should have submits disabled.
        if (evaluate) {
            await this.evaluateSubmitDisabling();
        }
    }

    /**
     * Update Validation Rules when new validation object is assigned
     * @param validationObj
     * @param evaluate
     */
    updateValidationRules(validationObj, { evaluate = true } = {}) {
        new Promise(async resolve => await resolve(
            this.updateValidationRulesAsync(validationObj, { evaluate })
        ));
    }

    /**
     * (Asynchronous) Update Validation Rules when new validation object is assigned
     * @param validationObj
     * @param evaluate
     */
    async updateValidationRulesAsync(validationObj, { evaluate = true } = {}) {
        const { inputs, customErrorMessages, customValidationFunctions } = validationObj;

        if (customErrorMessages) {
            this.fn.errorMessages = {
                ...this.fn.errorMessages,
                customErrorMessages
            };
        }

        if (customValidationFunctions) {
            Object.keys(customValidationFunctions).forEach(fnName => {
                this.fn[fnName] = customValidationFunctions[fnName];
            });
        }

        if (inputs) {
            await this.addValidationRules(inputs, { evaluate });
        }
    }

    /**
     * Remove specific Validation Rules from current Validator Instance
     * @param validationRulesToDelete
     * @param evaluate
     */
    async removeValidationRules(validationRulesToDelete, { evaluate = true } = {}) {
        new Promise(async resolve => await resolve(
            this.removeValidationRulesAsync(validationRulesToDelete, { evaluate })
        ));
    }

    /**
     * (Asynchronous) Remove specific Validation Rules from current Validator Instance
     * @param validationRulesToDelete
     * @param evaluate
     */
    async removeValidationRulesAsync(validationRulesToDelete, { evaluate = true } = {}) {
        validationRulesToDelete.forEach(name => {
            if (this.validationRules.hasOwnProperty(name)) {
                delete this.validationRules[name];
            }

            if (this.validationInputLabels.hasOwnProperty(name)) {
                delete this.validationInputLabels[name];
            }

            if (this.relatedElementsConfig.hasOwnProperty(name)){
                delete this.relatedElementsConfig[name];
            }

            if (this.customErrorConfig.hasOwnProperty(name)) {
                delete this.customErrorConfig[name];
            }

            if (this.hiddenInputNames.includes(name)) {
                const index = this.hiddenInputNames.indexOf(name);
                this.hiddenInputNames.splice(index, 1);
            }
        });

        this.remapFormElements();

        // We've removed rules. Check to see if we should have submits disabled.
        if (evaluate) {
            await this.evaluateSubmitDisabling();
        }
    }

    /**
     * Check to see if submit needs disabled
     * @param displayErrors
     */
    async evaluateSubmitDisabling(displayErrors = false){
        if (this.config.allowSubmitButtonDisabling) {
            const valid = (this.config.validateCustomRulesForButtonEnabling)
                ? await this.validateFormForInputCustomRules(displayErrors)
                : await this.validateFormForRequiredInputs(displayErrors);

            if (valid) {
                this.enableSubmitButtons();
            }
            else {
                this.disableSubmitButtons();
            }

            this.onAfterEvaluateSubmitDisabling(valid);
        }
    }

    /**
     * Validate the Form
     * @param displayErrors
     * @return {boolean}
     */
    async validateForm(displayErrors = true) {
        const valid = await new Promise(async (resolve, reject) => {
            const ok = await this.onBeforeValidation();
            if (ok) {
                resolve();
            }
            else {
                reject();
            }
        })
            .then(async () => {
                if (!this.formElements) return true;
                return await Promise.all(
                    this.formElements.map(async el => await this.validateInput(el, { displayErrors }))
                )
                    .then(() => Object.keys(this.validationErrors).every(name => this.validationErrors[name].length === 0));
            })
            .then(async valid => {
                // Run onAfterValidation callback
                this.onAfterValidation(this.validationErrors, valid);

                // Returns the result of the above validation.
                return valid;
            })
            .catch(err => {
                return false; // not valid.
            });

        if (!valid && this.config.allowScroll) {
            this.scrollToFirstInvalidInput();
        }

        return valid;
    }

    /**
     * Validate the Form to ensure all 'required' inputs are filled out. All other rules are ignored here.
     * @param displayErrors
     * @return {boolean}
     */
    async validateFormForRequiredInputs(displayErrors = false) {
        const valid = await Promise.all(
            this.formElements.map(async el => await this.validateInput(el, {
                displayErrors,
                onlyInputRulesToWatch: ['required', 'required_when', 'required_as_group']
            }))
        )
            .then(() => Object.keys(this.validationErrors).every(name => this.validationErrors[name].length === 0));

        return valid;
    }

    /**
     * Validate the Form to ensure all 'required' inputs are filled out. All other rules are ignored here.
     * @param displayErrors
     * @return {boolean}
     */
    async validateFormForInputCustomRules(displayErrors = false) {
        const valid = await Promise.all(
            this.formElements.map(async el => await this.validateInput(el, {
                displayErrors,
                onlyInputRulesToWatch: this.config.customRulesForButtonEnabling
            }))
        )
            .then(() => Object.keys(this.validationErrors).every(name => this.validationErrors[name].length === 0));

        return valid;
    }

    /**
     * Validate a single Input Field
     * @param el                        {HTMLElement}   The input to validate
     * @param displayErrors             {boolean}       Display errors?
     * @param onlyInputRulesToWatch     {array}         Optional. Pass in specific validation rule names, and all other globally assigned rules will be ignored
     * @param scrollToFirstInvalidInput {boolean}
     */
    async validateInput(el, {
        displayErrors = true,
        onlyInputRulesToWatch = [],
        scrollToFirstInvalidInput = false
    } = {}) {
        let inputErrors = [];
        try {
            inputErrors = await this._mapValidationErrorsForInput(el, {
                onlyInputRulesToWatch
            });
        } catch(e) {
            console.error(e);
        }

        if (inputErrors.length > 0) {
            if (displayErrors) {
                const pass = this.onBeforeErrorDisplay(el, inputErrors[0][0]);
                if(!pass){
                    this.clearErrorsForInput(el);
                    return;
                }
                this.generateErrorsForInput(el, inputErrors[0][1]); // Only output the first error
                if (this.config.allowScroll && scrollToFirstInvalidInput) {
                    this.scrollToFirstInvalidInput();
                }
            }
            return false;
        }
        else {
            this.clearErrorsForInput(el);
            return true;
        }
    }

    /**
     * Mapping for complete collection of ValidationErrors object
     * @param initialState  {boolean}
     * @return {object}
     * @private
     */
    _mapValidationErrors(initialState = false) {
        const previousValidationErrors = !initialState
            ? (this.validationErrors || [])
            : [];

        return this.formElementCollection.reduce((validationErrors, formElement) => {
            const {
                originalName,
                name,
                multidimensional = false // multidimensional array... like "testimonials[0][comments]"
            } = formElement;

            const keyname = !multidimensional ? name : originalName;

            const {
                [name]: validationError = []
            } = previousValidationErrors;

            return {
                ...validationErrors,
                [keyname]: validationError
            };
        }, {});
    }

    /**
     * Map InputErrors, and append to main ValidationErrors object
     * @param el                        {HTMLElement}   The input to validate
     * @param onlyInputRulesToWatch     {array}         Optional. Pass in specific validation rule names, and all other globally assigned rules will be ignored
     * @private
     * @return {Array}
     */
    async _mapValidationErrorsForInput(el, {
        onlyInputRulesToWatch = []
    } = {}) {
        // Get Collected Input Data
        const {
            originalName = null,
            name = null,
            group = [],
            type,
            multidimensional = false,
            errorConfig = null
        } = this.formElementCollection.find(formElement => formElement.originalName === el.name);

        // Input Validation & Sanitization Rules
        let {
            [name]: inputRules = []
        } = this.validationRules;

        if (onlyInputRulesToWatch.length > 0) {
            inputRules = inputRules.filter(rule => (
                onlyInputRulesToWatch.includes(rule)
                || onlyInputRulesToWatch.some(v => rule.includes(v))
            ));
        }

        // Append additional validation rule, so that all text inputs must only allow sanitized text values.
        // No HTML, or other invalid text will be allowed.
        const inputsToSkipForAutoSanitization = ['password', 'member_email_tags_input'];
        if (
            isTextElement(el)
            && (
                !inputRules.includes('sanitized_text')
                && !(inputRules.some(rule => rule.includes('sanitized_text')))
            )
            && !inputsToSkipForAutoSanitization.includes(name)
        ) {
            inputRules = [...inputRules, 'sanitized_text'];
        }

        /* DatePicker date format validation */
        if (type === 'date-picker' && el.getAttribute('data-date-format')) {
            const dateFormat = el.getAttribute('data-date-format');
            let dateFormatValidationRule;
            if (dateFormat === 'mm/dd/yy') dateFormatValidationRule = 'valid_date_format_mmddyyyy';
            if (dateFormat === 'yy/mm/dd') dateFormatValidationRule = 'valid_date_format_yyyymmdd';
            if (dateFormat === 'M dd, yy') dateFormatValidationRule = 'valid_date_format'; /* standard */
            if (dateFormatValidationRule) {
                inputRules = [...inputRules, dateFormatValidationRule];
            }
        }

        let inputSanitizationRules = inputRules.filter(rule => {
            if (typeof rule !== 'string') {
                return false;
            }

            return [
                'trim',
                'sanitize_emoji',
                'sanitize_alt_code'
            ].includes(rule);
        });

        if (
            this.config.allowTrimOnAllTextFields
            && isTextElement(el)
            && (onlyInputRulesToWatch.length === 0 || onlyInputRulesToWatch.includes('trim'))
        ) {
            inputSanitizationRules = [
                ...inputSanitizationRules,
                'trim'
            ];
        }

        const inputValidationRules = inputRules.reduce((inputValidationRulesArray, rule) => {
            const isValidationRule = (
                (typeof rule === 'string' && !inputSanitizationRules.includes(rule))
                || typeof rule !== 'string'
            );

            if (!isValidationRule) {
                return inputValidationRulesArray;
            }

            return [
                ...inputValidationRulesArray,
                rule
            ];
        }, []);

        // Does this input require validation?
        // Must have defined validationRules assigned to this input.
        const validationRequiredForInput = inputRules.length > 0;

        // Name to reference when appending validationErrors
        const nameMappedToValidationError = !multidimensional ? name : originalName;

        // Split input rules to an array of rules
        // And validate that each inputted rule is a valid rule based on the Validator's available functions
        let inputErrors = [];
        if (validationRequiredForInput) {
            const inputValidationRulesAreValid = this._checkInputValidationRulesAreValid(inputValidationRules);
            if (!inputValidationRulesAreValid) {
                throw new Error('Input validation rules do not match the defined ValidationFunctions mapping.');
            }

            const inputSanitizationRulesAreValid = this._checkInputSanitizationRulesAreValid(inputSanitizationRules)
            if (!inputSanitizationRulesAreValid) {
                throw new Error('Input sanitization rules do not match the defined Sanitization functions mapping.');
            }

            // Run Sanitization before doing Validation
            inputSanitizationRules.forEach(rule => {
                const sanitizedValue = this.sanitizationFn[rule](el.value);
                el.value = sanitizedValue;
            });

            // Run Validation
            inputErrors = (await Promise.all(
                inputValidationRules.map(async rule => {
                    let customErrorMessages = {};
                    if (errorConfig && errorConfig.hasOwnProperty('messages')) {
                        customErrorMessages = errorConfig.messages;
                    }

                    // If input is apart of a radio or checkbox group, then first check if any grouped elements are already valid/checked
                    // If YES, then we can stop here. Since radio or checkbox groups only need a single item to be valid in order to continue
                    if (
                        (type === 'radio' || type === 'checkbox')
                        && inputValidationRules.includes('required')
                        && group.some(option => option.checked === true)
                    ) {
                        return;
                    }

                    let inputValidationResponse;
                    // -- Basic Functions --
                    if (typeof rule === 'string') {
                        if (rule.indexOf('maxlength:') === 0) {
                            const [, limit] = rule.split(':');
                            inputValidationResponse = this.fn.maxlength(el, {
                                limit: parseInt(limit),
                                customErrorMessage: customErrorMessages.hasOwnProperty('maxlength') ? customErrorMessages.maxlength : null
                            });
                        }
                        else if (rule.indexOf('minlength:') === 0) {
                            const [, limit] = rule.split(':');
                            inputValidationResponse = this.fn.minlength(el, {
                                limit: parseInt(limit),
                                customErrorMessage: customErrorMessages.hasOwnProperty('minlength') ? customErrorMessages.minlength : null
                            });
                        }
                        else if (rule.indexOf('max_value:') === 0) {
                            const [, amount] = rule.split(':');
                            inputValidationResponse = this.fn.max_value(el, {
                                amount,
                                customErrorMessage: customErrorMessages.hasOwnProperty('max_value') ? customErrorMessages.max_value : null
                            });
                        }
                        else if (rule.indexOf('min_value:') === 0) {
                            const [, amount] = rule.split(':');
                            inputValidationResponse = this.fn.min_value(el, {
                                amount,
                                customErrorMessage: customErrorMessages.hasOwnProperty('min_value') ? customErrorMessages.min_value : null
                            });
                        }
                        else if (rule.indexOf('custom:') === 0) {
                            // Custom Function (Basic Function)
                            // ie 'custom:rule_name_here' ... must have also passed in customValidationFunctions parameter on initialization
                            const customRuleName = rule.replace('custom:', '');
                            inputValidationResponse = await this.fn[customRuleName](el);
                        }
                        else {
                            inputValidationResponse = await this.fn[rule](el, {
                                customErrorMessage: customErrorMessages.hasOwnProperty(rule) ? customErrorMessages[rule] : null
                            });
                        }
                    }
                    // -- Advanced Functions --
                    // ie: ['rule_name_here', { param1: '', param2: '' }]
                    else if (typeof rule !== 'string' && Array.isArray(rule)) {
                        const [ruleName, ruleParams] = rule;
                        // Custom Function (Advanced Function)
                        // ie: ['custom:rule_name_here', { param1: '', param2: ''}] ... must have also passed in customValidationFunctions parameter on initialization
                        if (ruleName.indexOf('custom:') === 0) {
                            const customRuleName = ruleName.replace('custom:', '');
                            inputValidationResponse = await this.fn[customRuleName](el, ruleParams);
                        }
                        else {
                            inputValidationResponse = await this.fn[ruleName](el, {
                                ...ruleParams,
                                customErrorMessage: customErrorMessages.hasOwnProperty(ruleName) ? customErrorMessages[ruleName] : null
                            });
                        }
                    }

                    // ValidationFunction failed?
                    let errorMessage = null;
                    if (inputValidationResponse !== true) {
                        errorMessage = (inputValidationResponse instanceof ValidationError)
                            ? [rule, inputValidationResponse.getErrorMessage()]
                            : [rule, inputValidationResponse];

                        errorMessage = this.filterErrorMessage(nameMappedToValidationError, errorMessage);
                    }


                    return errorMessage;
                })
            ))
                .filter(errorMessage => !!errorMessage)
        }

        // Assign Errors
        if (this.inputNamesWithForcedValidity.includes(nameMappedToValidationError)) inputErrors = [];
        this.validationErrors[nameMappedToValidationError] = inputErrors;

        // Sendback response
        return inputErrors;
    }

    /**
     * Check that all Input's Validation Rules are assigned correctly
     * @param inputValidationRules
     * @return {boolean}
     * @private
     */
    _checkInputValidationRulesAreValid(inputValidationRules = []) {
        return inputValidationRules.every(rule => {
            // -- Basic Functions --
            let ruleName = rule;

            // Maximum length
            if (typeof rule === 'string' && rule.indexOf('maxlength:') >= 0) {
                ruleName = 'maxlength';
            }

            // Minimum length
            if (typeof rule === 'string' && rule.indexOf('minlength:') >= 0) {
                ruleName = 'minlength';
            }

            // Maximum Value (of a specific number)
            if (typeof rule === 'string' && rule.indexOf('max_value:') >= 0) {
                ruleName = 'max_value';
            }

            // Minimum Value (of a specific number)
            if (typeof rule === 'string' && rule.indexOf('min_value:') >= 0) {
                ruleName = 'min_value';
            }

            // Member Violations could potentially have an ?ignore parameter attached
            if (typeof rule === 'string' && rule.indexOf('check_member_violations') >= 0) {
                ruleName = 'check_member_violations';
            }

            // Custom Function (Basic Function)
            // id: 'custom:rule_name_here' ... must have also passed in customValidationFunctions parameter on initialization
            if (typeof rule === 'string' && rule.indexOf('custom:') >= 0) {
                ruleName = rule.replace('custom:', '');
            }

            // -- Advanced Functions --
            // id: ['rule_name_here', { param1: '', param2: '' }]
            if (typeof rule !== 'string' && Array.isArray(rule)) {
                [ ruleName ] = rule;

                // Custom Function (Advanced Function)
                // ie: ['custom:rule_name_here', { param1: '', param2: ''}] ... must have also passed in customValidationFunctions parameter on initialization
                if (typeof ruleName === 'string' && ruleName.indexOf('custom:') >= 0) {
                    ruleName = ruleName.replace('custom:', '');
                }
            }

            // Ensure this function name was defined correctly...
            if (typeof ruleName !== 'string') {
                return false;
            }

            // Ensure this function name exists within the collection of ValidationFunctions
            return typeof this.fn[ruleName] === 'function';
        });
    }

    /**
     * Check that all Input's Sanitization Rules are assigned correctly
     * @param inputSanitizationRules
     * @return {boolean}
     * @private
     */
    _checkInputSanitizationRulesAreValid(inputSanitizationRules = []) {
        return inputSanitizationRules.every(rule => {
            // Ensure this function name was defined correctly...
            if (typeof rule !== 'string') {
                return false;
            }

            // Ensure this function name exists within the collection of ValidationFunctions
            return typeof this.sanitizationFn[rule] === 'function';
        });
    }

    /**
     * Generate Errors for a single Input Field
     * @param el
     * @param inputError
     */
    generateErrorsForInput(el, inputError) {
        // Get Input Data
        const {
            originalName = null,
            name,
            type,
            multidimensional = false,
            errorConfig = null,
            relatedElements = null
        } = this.formElementCollection.find(formElement => formElement.originalName === el.name);

        // Stop here if prevent Errors from being displayed.
        // error: { preventErrorDisplay: true }
        let preventErrorDisplay = false;
        if (errorConfig !== null) {
            preventErrorDisplay = (errorConfig.hasOwnProperty('preventErrorDisplay') && errorConfig.preventErrorDisplay === true);
        }
        if (preventErrorDisplay) {
            return;
        }

        // Apply Error Class on Input
        this._applyErrorStylingForInput(el, {
            type,
            relatedElements,
            errorConfig
        });

        // Name to reference when appending validationErrors
        const nameMappedToValidationError = !multidimensional ? name : originalName;

        // Unique Error Messages for this specific Input / InputGroup
        let newError = true;
        this.validationErrors[nameMappedToValidationError].forEach(error => {
            if(error[1] == inputError){
                newError = false;
            }
        });
        if(newError){
            this.validationErrors[nameMappedToValidationError].push([`custom:${inputError}`,inputError]);
        }

        // Identified by id attribute, or specific input with this unique name attribute
        const identifierName = !multidimensional
            ? name
            : el.id || originalName;

        // Generate, or find the Input's Message element
        let errorMessageElement = this.form.querySelector(`[data-error-for="${identifierName}"]`);
        if (!errorMessageElement) {
            errorMessageElement = document.createElement('div');
            errorMessageElement.setAttribute('data-error-for', identifierName);

            if (errorConfig && errorConfig.hasOwnProperty('customDataAttributes')) {
                Object.keys(errorConfig.customDataAttributes).forEach(dataAttribute => {
                    errorMessageElement.setAttribute(
                        dataAttribute,
                        errorConfig.customDataAttributes[dataAttribute]
                    );
                });
            }

            let referenceElement = el;

            // Modify what element will be the reference element when inserting the error message...
            switch (type) {
                // Voices radio & checkbox input groups
                case 'checkbox':
                case 'radio':
                    const inputGroup = el.parentNode.parentNode;
                    if (
                        inputGroup.classList.contains('radio-input-group')
                        || inputGroup.classList.contains('radio-card-group')
                        || inputGroup.classList.contains('checkbox-input-group')
                    ) {
                        referenceElement = inputGroup;
                    }
                    break;

                // Choices.js Selectors
                case 'select-one':
                case 'select-multiple':
                    const choicesContainer = el.parentNode.parentNode;
                    if (choicesContainer.classList.contains('choices')) {
                        referenceElement = choicesContainer;
                    }
                    break;

                case 'text':
                    // Custom Voices.com TagsInput
                    if (el.classList.contains('tags-input__input')) {
                        referenceElement = el.parentNode;
                    }
                    break;
            }

            // Error Config - Override where the error message gets displayed
            if (errorConfig && errorConfig.hasOwnProperty('insertAfter')) {
                if ({}.toString.call(errorConfig.insertAfter) === '[object Function]') {
                    referenceElement = errorConfig.insertAfter();
                } else {
                    referenceElement = errorConfig.insertAfter;
                }
            }

            // Display Message
            insertAfter(errorMessageElement, referenceElement);
        }

        // Update Element Class and Message
        errorMessageElement.className = `${this.config.inputNotificationClass} ${this.config.inputErrorNotificationClass}`;
        errorMessageElement.innerHTML = inputError;
    }

    /**
     * Apply Error Styling for Input Element
     * @param {HTMLElement} el              Input which will receive the error styling
     * @param {string} type                 Input type
     * @param {null|array} relatedElements  Related elements (ie- group of radio buttons)
     * @param {null|object} errorConfig     Custom error configurations
     * @private
     */
    _applyErrorStylingForInput(el, {
        type,
        relatedElements = null,
        errorConfig = null
    } = {}) {
        // Stop here if styling has been disabled.
        if (errorConfig
            && errorConfig.hasOwnProperty('disableErrorStyling')
            && errorConfig.disableErrorStyling === true
        ) {
            return;
        }

        switch (type) {
            // Choices.js
            case 'select-one':
            case 'select-multiple':
                const choicesInnerElement = el.parentNode;
                if (choicesInnerElement.classList.contains('choices__inner')) {
                    choicesInnerElement.classList.add(this.config.inputErrorClass);
                }
                break;

            // Any other type of input element
            default:
                el.classList.add(this.config.inputErrorClass);
                break;
        }

        if (relatedElements) {
            relatedElements.forEach(el => {
                if(!el.disabled){
                    el.classList.add(this.config.inputErrorClass)
                }
            });
        }
    }

    /**
     * Clear Errors for a single Input Field
     * @param el
     */
    clearErrorsForInput(el) {
        // Get Input Data
        if(!el) return;
        if(typeof(this.formElementCollection) == "undefined") return;
        const targetElement = this.formElementCollection.find(formElement => formElement.originalName === el.name);

        if(typeof(targetElement) == "undefined") return; //Make sure the element exists in the form collection before we proceed to avoid errors in the below destructuring

        const {
            originalName = null,
            name,
            type,
            group,
            multidimensional = false,
            relatedElements = null
        } = targetElement;

        // Remove error class on any inputs in this group
        group.forEach(groupedElement => {
            switch (type) {
                // Choices.js
                case 'select-one':
                case 'select-multiple':
                    const choicesInnerElement = el.parentNode;
                    if (choicesInnerElement.classList.contains('choices__inner')) {
                        choicesInnerElement.classList.remove(this.config.inputErrorClass);
                    }
                    break;

                // Any other type of input element
                default:
                    groupedElement.classList.remove(this.config.inputErrorClass);
                    break;
            }
        });

        if (relatedElements) {
            relatedElements.forEach(el => el.classList.remove(this.config.inputErrorClass));
        }

        // Identified by id attribute, or specific input with this unique name attribute
        const identifierName = !multidimensional
            ? name
            : el.id || originalName;

        const errorMessageElement = this.form.querySelector(`[data-error-for="${identifierName}"]`);

        if (errorMessageElement && errorMessageElement.parentNode) {
            errorMessageElement.parentNode.removeChild(errorMessageElement);
        }
    }

    /**
     * Force validity on specific input
     * @param inputName
     */
    addToForcedValidityArray(inputName) {
        if (this.validationErrors.hasOwnProperty(inputName) && !this.inputNamesWithForcedValidity.includes(inputName)) {
            this.inputNamesWithForcedValidity = [...this.inputNamesWithForcedValidity, inputName];
        }
    }

    /**
     * Enable default validation on specific input
     * @param inputName
     */
    removeFromForcedValidityArray(inputName) {
        if (this.validationErrors.hasOwnProperty(inputName) && this.inputNamesWithForcedValidity.includes(inputName)) {
            this.inputNamesWithForcedValidity = this.inputNamesWithForcedValidity.filter(item => item !== inputName);
        }
    }

    /**
     * Clear Error from being displayed on Input
     * @param el
     * @param inputError
     */
    clearErrorFromInput(el, inputError) {
        // Get Input Data
        const {
            originalName = null,
            name,
            multidimensional = false
        } = this.formElementCollection.find(formElement => formElement.originalName === el.name);

        // Name to reference when referencing validationErrors
        const nameMappedToValidationError = !multidimensional ? name : originalName;

        const [ firstInputError ] = this.validationErrors[nameMappedToValidationError];
        if (firstInputError[1] === inputError) {
            this.clearErrorsForInput(el);
        }
    }

    /**
     * Append Submit Button's [name:value] to the Form Data
     *
     * Note: Form has been validated, and right before the form.submit() action fires, this will get handled...
     *
     * @param submitter
     * @private
     */
    _appendSubmitButtonToFormDataWhenValidated(submitter) {
        const hiddenInput = document.createElement('input');
        hiddenInput.name = submitter.name;
        hiddenInput.type = 'hidden';
        hiddenInput.value = submitter.value || true;
        this.form.appendChild(hiddenInput);

        // Remove name off submitter, so no duplicate names exist in form body
        submitter.removeAttribute('name');
    }

    /**
     * Scroll to first invalid input on Form Validation
     */
    scrollToFirstInvalidInput() {
        if (!this.config.allowScroll) {
            return;
        }

        // Filter out any inputs that have been successfully validated, and have 0 errors
        const invalidInputNames = Object.keys(this.validationErrors).filter(name => {
            const valid = this.validationErrors[name].length === 0;
            return !valid;
        });

        // Find the first input that was listed from the invalids array
        const [firstInvalidInputName] = invalidInputNames;

        // Find actual HTMLElement(s) from the mapped out input name
        const firstInvalidInput = this.form.querySelector(`[name^="${firstInvalidInputName}"]`);
        const firstInvalidInputLabel = !!firstInvalidInput
            ? this.form.querySelector(`label[for="${firstInvalidInput.getAttribute('id')}"]`)
            : null;

        // Scroll to the first invalid input
        const targetElement = (firstInvalidInputLabel || firstInvalidInput);
        if (targetElement) {
            ScrollTo.element(targetElement);
        }
    }

    /**
     * Submit Button was clicked, disable button(s) & attach loading spinner
     * @param submitBtn         {HTMLElement}
     */
    disableSubmitButton(submitBtn = null) {
        if (!this.config.allowSubmitButtonProcessing) {
            return;
        }

        submitBtn.dataset.originalContent = submitBtn.innerHTML;

        const spinner = document.createElement('i');
        spinner.className = 'far fa-circle-notch fa-spin';
        spinner.style.marginLeft = '0.4rem';

        if (submitBtn) {
            let buttonWidth = 0;
            if (!submitBtn.dataset.buttonWidth) {
                buttonWidth = parseInt(window.getComputedStyle(submitBtn).width);
                submitBtn.dataset.buttonWidth = buttonWidth.toString();
            } else {
                buttonWidth = parseInt(submitBtn.dataset.buttonWidth);
            }

            submitBtn.style.minWidth = buttonWidth + 'px';

            submitBtn.setAttribute('disabled', true);
            submitBtn.innerText = this.config.submitButtonProcessingText;
            submitBtn.appendChild(spinner);

            // Additional buttons to disable when processing...
            // ie - perhaps a "Cancel" button?
            this.buttonsToDisableWhenProcessing.forEach(btn => {
                if (!btn) {
                    return;
                }

                btn.setAttribute('disabled', true);
            });
        }
    }

    /**
     * Button(s) becomes enabled again. Also, allow a small delay so we can see the spinner in action quickly.
     * @param submitBtn             {HTMLElement}
     * @param submitBtnText         {string}
     * @param timeoutDelay          {int}
     */
    enableSubmitButton({
        submitBtn = null,
        submitBtnText = 'Submit',
        timeoutDelay = 500
    } = {}) {
        if (!this.config.allowSubmitButtonProcessing) {
            return;
        }

        if (submitBtn.dataset.originalContent) {
            submitBtnText = submitBtn.dataset.originalContent;
        }

        setTimeout(() => {
            submitBtn.removeAttribute('disabled');
            submitBtn.innerHTML = submitBtnText;

            // Additional buttons to re-enable after processing...
            // ie - perhaps a "Cancel" button?
            this.buttonsToDisableWhenProcessing.forEach(btn => {
                if (!btn) {
                    return;
                }

                btn.removeAttribute('disabled');
            });
        }, timeoutDelay);
    }

    addSubmitButtons(buttons) {
        if (!buttons || !Array.isArray(buttons)) return;
        buttons.forEach(button => this.submitBtns.push(button));
    }

    addButtonsToDisableWhenProcessing(buttons) {
        if (!buttons || !Array.isArray(buttons)) return;
        buttons.forEach(button => this.buttonsToDisableWhenProcessing.push(button));
    }

    isThereValidationErrors() {
        return Object.values(this.validationErrors).some(errorList => errorList.length > 0);
    }

    disableAllowScrolling() {
        this.config.allowScroll = false;
    }

    enableAllowScrolling() {
        this.config.allowScroll = true;
    }
}

module.exports = Validator;