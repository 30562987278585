const defaultErrorMessages = require('./errorMessages');

class ValidationError {

    /**
     * Constructor
     * @param el                        {HTMLElement}   Element that triggered the error
     * @param error                     {string}        Error Key Name. ie- "required", "maxlength", etc. Must match to the exact ValidationFunction
     * @param customErrorMessage        {string}        Custom Error Message - which will overwrite the default error message
     * @param params                    {object}        Additional parameters to be plugged into Error Message output
     */
    constructor(el = null, {
        error = null,
        customErrorMessage = null,
        params = {}
    } = {}) {
        const displayName = el.getAttribute('data-display-name') || null;

        const {
            [error]: defaultErrorMessageFn = () => 'Error'
        } = defaultErrorMessages;

        const defaultErrorMessage = defaultErrorMessageFn({
            name: displayName,
            ...params
        });

        // Define Error Message
        if (!customErrorMessage || typeof customErrorMessage !== 'string') {
            this.errorMessage = defaultErrorMessage;
        }
        else {
            this.errorMessage = customErrorMessage;
        }

        // Define Error Key Name
        this.error = error;
    }

    /**
     * Get Error Message
     * @return {*}
     */
    getErrorMessage() {
        return this.errorMessage;
    }

}

module.exports = ValidationError;
