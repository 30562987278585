const Validator                 = require('../sharable/Validator');
const PasswordValidationWidget  = require('../sharable/FormComponent/widgets/PasswordValidationWidget.js');
const { debounce } = require('../sharable/Utilities'); // global utilities
import { buttonSend, buttonReturn } from '@js/sharable/buttonSend';

//SSO ClientID and RedirectURI
const APPLE_CLIENT_ID = (!!document.querySelector('[name=\'apple_oauth_client_id\']')) ? document.querySelector('[name=\'apple_oauth_client_id\']').value : null;
const GOOGLE_CLIENT_ID = (!!document.querySelector('[name=\'google_oauth_client_id\']')) ? document.querySelector('[name=\'google_oauth_client_id\']').value : null;
const APPLE_REDIRECT_URI = (!!document.querySelector('[name=\'apple_redirect_uri\']')) ? document.querySelector('[name=\'apple_redirect_uri\']').value : null;

class SignUpValidator extends Validator {
    constructor(form, {
        inputs = {},
        submitButtons = [],
        buttonsToDisableWhenProcessing = [],
        allowSubmitButtonDisabling = true,
        customValidationFunctions = {},
        customRulesForButtonEnabling = [],
        validateCustomRulesForButtonEnabling = true,
        onBeforeErrorDisplay = null,
        onAfterValidation = null
    } = {}) {
        super(form, {
            inputs,
            submitButtons,
            buttonsToDisableWhenProcessing,
            allowSubmitButtonDisabling,
            customValidationFunctions,
            customRulesForButtonEnabling,
            validateCustomRulesForButtonEnabling,
            onBeforeErrorDisplay,
            onAfterValidation
        });
    }
    _attachEventListenersToSubmitButtons() { // Let signup step logic handle this.
        return;
    }
}

class SignUpForm {
    constructor({
        onSuccessfulSubmit = null,
        initialAccountPath = null,
        initialAccountPathHide = false,
        title = null
    } = {}) {
        this.form = document.getElementById('signup-form');
        this.currentStep = this.form.dataset.initialStep || 1;

        this.isSocialSignup = !!(document.querySelector('input[name="is_social_signup"] ').value);

        this.continueBtn = document.getElementById('continue-setup');

        this.signupNowBtn = document.getElementById('signup-now');

        this.submitBtn = document.getElementById('submit-signup');

        this.backBtn = document.getElementById('signup-back-link');

        this.securityFields = this.form.querySelector('input[name="security_token"]');

        this.privateInvitee = this.form.dataset.quotedMemberName;

        this.signupTitle = document.querySelector(`#signup-title`);

        this.currentHelperText = document.querySelector(`#signup-helper-text`);

        this.talentSelected = document.querySelector(`input[name="account_type"][value="guest"]`);

        this.clientSelected = document.querySelector(`input[name="account_type"][value="basic"]`);

        this.nonSocialStep = document.getElementById('member-info-non-social');

        this.socialInfoContainer = document.getElementById('social-infos');

        this.socialSignUpTitle = document.getElementById('signup-heading');

        this.loginLink = this.form.querySelector('#login-instead');

        this.setAccountPathHandling({ initialAccountPath, initialAccountPathHide });

        this.signupModalOpenedByOrderPackageButton = false;

        this.voicesSignup = document.querySelector('#voices-signup');

        //Google Initialization
        // this.auth2 = null; // The Sign-In object.
        this.googleUser = null; // The current user.
        this.googleSSIButton = null;

        /**
         * Apple SSO
         */

        //Configure the Apple Auth Object
        AppleID.auth.init({
            clientId: APPLE_CLIENT_ID,
            scope: 'name email',
            redirectURI: APPLE_REDIRECT_URI,
            usePopup: true
        });


        const signupBtn = document.getElementById('apple-sso-signup');
        signupBtn.addEventListener('click', e => {
            e.preventDefault();
            AppleID.auth.signIn().then(response => this._handleAppleIDSignInOnSuccessForSignup(response));
        });

        //Listen for authorization failures
        document.addEventListener('AppleIDSignInOnFailure', (error) => {
            //handle error.
            //console.log(error);
        });

        this.title = title;

        //Only bind these items if we aren't on the social signup form.
        if (!this.isSocialSignup) {
            this.showPassword = this.form.querySelector('.form-password-show');
            this.passwordInput = document.getElementById('password-signup');
            this.passwordValidationWidget = new PasswordValidationWidget(document.getElementById('password-signup'));
            this.loginLink = this.form.querySelector('#login-instead');
        }

        if(!!window.location.pathname.includes('/signup/iframe')){

            const {
                js_variables: {
                    signupStep1: signupStep1 = '',
                    signupStep2: signupStep2 = ''

                }
            } = JSON.parse(document.getElementById('iframe-js-variables').getAttribute('data-object'));

            this.signUpStep1Data = JSON.parse(signupStep1);
            this.signUpStep2Data = JSON.parse(signupStep2);

            this.continueBtn.style.backgroundColor = this.signUpStep1Data['submit_disabled_cta_color'];
        }

        this.setOnSuccessfulSubmit(onSuccessfulSubmit);

        this.initialValidationRules = this._instantiateValidationRules();

        this.validator = new SignUpValidator(this.form, this._VALIDATION_OBJECT);

        this.renderStep(this.currentStep);
        this.initializeGclidReceiver();
        this.addEventListeners();
    }

    set title(title) {
        if (!window.location.pathname.includes('/signup/iframe')) {
            this.currentTitle = title || 'Sign Up for Free';
            if (!this.signupTitle) this.signupTitle = document.querySelector(`#signup-title`);
            if (this.signupTitle) this.signupTitle.innerHTML = this.currentTitle;
        }
    }

    showCompanyPhoneFields() {
        if (this.voicesSignup && this.voicesSignup.dataset && this.voicesSignup.dataset.hideCompanyPhone) {
            return parseInt(this.voicesSignup.dataset.hideCompanyPhone) === 1;
        } else {
            return false;
        }
    }

    setOnSuccessfulSubmit(callback) {
        this.onSuccessfulSubmit = (typeof callback === 'function')
                                  ? async (type, memberInfo) => await callback(type, memberInfo)
                                  : async (type, memberInfo) => {
                return true;
            };
    }

    setAccountPathHandling({
        initialAccountPath = null,
        initialAccountPathHide = false
    } = {}) {
        if (initialAccountPath) {
            const initialPath = document.querySelector(`input[name="account_type"][value="${initialAccountPath}"]`);
            if (initialPath) {
                initialPath.checked = true;
                if (initialAccountPathHide) {
                    const accountSelection = document.getElementById('signup-account-selection');
                    if (accountSelection) accountSelection.classList.add('hidden');
                }
            }
        }
    }

    get _VALIDATION_RULES() {
        if (this.isSocialSignup) {
            return {
                2: {
                    inputs: {
                        account_type: {
                            label: 'Account Type',
                            rules: [
                                'required'
                            ],
                            error: {
                                insertAfter: document.getElementById('account-type')
                            }
                        }
                    }
                },
                3: {
                    inputs: {
                        company: {
                            label: 'Company Name',
                            rules: [
                                'required',
                                'sanitized_text'
                            ],
                            error: {
                                insertAfter: document.getElementById('company')
                            }
                        },
                        phone: {
                            label: 'Phone Number',
                            rules: [
                                'required',
                                'valid_phone',
                                'sanitized_text'
                            ],
                            error: {
                                insertAfter: document.getElementById('phone')
                            }
                        },
                        policy: {
                            label: 'Terms of Service and Privacy Policy',
                            rules: [
                                'required'
                            ],
                            error: {
                                insertAfter: document.getElementById('policy-checkbox-container')
                            }
                        }
                    }
                }
            };
        } else {
            return {
                1: {
                    inputs: {
                        full_name: {
                            label: 'Full name',
                            rules: [
                                'required',
                                'check_member_violations',
                                'no_emojis',
                                'sanitized_text',
                                'custom:max_full_name_split_length'
                            ]
                        },
                        email: {
                            label: 'Email',
                            rules: [
                                'required',
                                'maxlength:255',
                                'valid_email',
                                'sanitized_text'
                            ],
                            error: {
                                messages: {
                                    'valid_email': 'Email is not a valid email address' // VWEB-4961:Password Security Imp.
                                }
                            }
                        },
                        account_type: {
                            label: 'Account type',
                            rules: [
                                'required'
                            ],
                            error: {
                                insertAfter: document.getElementById('account-type')
                            }
                        }
                    }
                },
                3: {
                    inputs: {
                        ...this.passwordValidationWidget.VALIDATION_OBJECT,
                        company: {
                            label: 'Company Name',
                            rules: [
                                'required',
                                'sanitized_text'
                            ],
                            error: {
                                insertAfter: document.getElementById('company')
                            }
                        },
                        phone: {
                            label: 'Phone Number',
                            rules: [
                                'required',
                                'valid_phone',
                                'sanitized_text'
                            ],
                            error: {
                                insertAfter: document.getElementById('phone')
                            }
                        },
                        policy: {
                            label: 'Terms of Service and Privacy Policy',
                            rules: [
                                'required'
                            ],
                            error: {
                                insertAfter: document.getElementById('policy-checkbox-container')
                            }
                        }
                    }
                }
            };
        }
    }

    get _VALIDATION_OBJECT() {
        if (this.isSocialSignup) {
            return {
                inputs: {
                    ...this.initialValidationRules.inputs
                },
                submitButtons: [this.continueBtn, this.signupNowBtn, this.submitBtn],
                buttonsToDisableWhenProcessing: [this.continueBtn, this.signupNowBtn, this.submitBtn],
                allowSubmitButtonDisabling: true
            };
        } else {
            return {
                inputs: {
                    ...this.initialValidationRules.inputs
                },
                customRulesForButtonEnabling: [
                    'required',
                    'valid_email',
                    'maxlength',
                    'sanitized_text',
                    'custom'
                ],
                validateCustomRulesForButtonEnabling: true,
                customValidationFunctions: {
                    max_full_name_split_length: el => this._validateFullNameSplitLength(el)
                },
                submitButtons: [this.continueBtn, this.signupNowBtn, this.submitBtn],
                buttonsToDisableWhenProcessing: [this.continueBtn, this.signupNowBtn, this.submitBtn],
                allowSubmitButtonDisabling: true,
                onBeforeErrorDisplay: (element, rule) => this.passwordValidationWidget.beforeDisplayErrors(element, rule),
                onAfterValidation: (validationErrors) => this.passwordValidationWidget.afterFormValidate(validationErrors)
            };
        }
    }

    get emailValue() {
        return this.form.querySelector('[name="email"]').value;
    }

    // Handle Callback function for google SSO
    handleCredentialResponse = (response) => {
        this.googleResponse = decodeJwtResponse(response.credential);
        this.googleResponse['provider'] = 'google';
        if (!!this.googleResponse.email) {
            this.performSocialSignUpSteps(this.googleResponse);
        }
    };

    initializeGoogleSSI() {
        if (window.location.pathname === '/signup' || window.location.pathname === '/studio' || window.location.pathname === '/signup/iframe') {
            window.onload = () => {
                google.accounts.id.initialize({
                    client_id: GOOGLE_CLIENT_ID,
                    callback: this.handleCredentialResponse
                });

                google.accounts.id.renderButton(
                    document.querySelector('#google-sign-up'),
                    {
                        // customization attributes
                        theme: 'outline',
                        width: 250,
                        text: 'continue_with'
                    }
                );
            };
        }

        document.addEventListener('click', (e) => {
            const target = e.target.closest('#error-banner-signup-link') || e.target.closest(`button[value="new_job"]`) || e.target.closest('#profile-message-talent');

            if (target) {
                google.accounts.id.initialize({
                    client_id: GOOGLE_CLIENT_ID,
                    callback: this.handleCredentialResponse
                });

                google.accounts.id.renderButton(
                    document.querySelector('#google-sign-up'),
                    {
                        // customization attributes
                        theme: 'outline',
                        width: 250,
                        text: 'continue_with'
                    }
                );
            }
        });

        this.addGoogleEventListeners();
    }

    addGoogleEventListeners() {
        // TODO: replace this with a better solution so we do not have to continue maintaining a specific selector list
        let selectorList = [
            '[data-door="sign-up"]',
            '#signup-instead',
            '#submit-addons-btn',
            '#order_total_card_button',
            '#order_total_card_button_mobile',
            '#order_message_talent_button',
            '[id^="whats-included-select-tier-"]',
            '#next-wrapper',
            '[data-bs-target="#frontdoor-modal"]' // This one is more generic and should cover all cases in theory
        ];
        document.querySelectorAll(selectorList.join(','))
                .forEach(el => {
                    // Specific Handling for specific selectors
                    if (el.classList.contains('order_button')) {
                        el.addEventListener('click', e => {
                            this.signupModalOpenedByOrderPackageButton = true;
                        });
                    } else {
                        this.signupModalOpenedByOrderPackageButton = false;
                    }

                    // Render Google SSO
                    if (el.getAttribute('google-initialized') !== 'true') {
                        el.addEventListener('click', e => {
                            google.accounts.id.initialize({
                                client_id: GOOGLE_CLIENT_ID,
                                callback: this.handleCredentialResponse
                            });
                            google.accounts.id.renderButton(
                                document.querySelector('#google-sign-up'),
                                {
                                    // customization attributes
                                    theme: 'outline',
                                    width: 250,
                                    text: 'continue_with'
                                }
                            );
                        });
                    }
                    el.setAttribute('google-initialized', 'true');
                });
    }

    //Listen for authorization success
    _handleAppleIDSignInOnSuccessForSignup(data) {
        const appleResponse = decodeJwtResponse(data.authorization.id_token);
        if (appleResponse) {
            this.appleResponse = appleResponse;
        }
        const appleSSOBtn = document.getElementById('apple-sign-in');
        if (appleSSOBtn) {
            appleResponse['provider'] = 'apple';
            appleResponse['response-code'] = data.authorization.code;
            appleResponse['response-state'] = data.authorization.state;
        }
        if (!!appleResponse) {
            this.performSocialSignUpSteps(this.appleResponse);
        }
    }

    /**
     * Show form
     */
    show() {
        this.form.classList.remove('hidden');
    }

    /**
     * Hide form
     */
    hide() {
        this.form.classList.add('hidden');
    }

    /**
     * Update value of email field from switching between modal forms.
     * @param val     The value to update the input with.
     */
    updateEmail(val) {
        if (!val) return;
        const email = this.form.querySelector('[name="email"]');
        email.value = val;
    }

    /**
     * Validate if full name input value split as first and last name matches the max character length
     * @param el                        {HTMLElement}   Input Field that is being validated
     * @return {boolean|string}
     * @private
     */
    _validateFullNameSplitLength(el) {
        const limit = 50;
        const fullName = el.value.trim();
        const firstName = fullName.split(' ').slice(0, -1).join(' ');
        const lastName = fullName.split(' ').slice(-1).join(' ');

        if (!!el.value) {
            if (firstName.length > limit) {
                return `First Name cannot exceed ${limit} characters`;
            }

            if (lastName.length > limit) {
                return `Last Name cannot exceed ${limit} characters`;

            }
        }
        return true;
    }

    _instantiateValidationRules() {
        let initialValidationRules = { inputs: {} };
        Object.keys(this._VALIDATION_RULES).forEach((stepKey) => {
            if (stepKey <= this.currentStep) {
                initialValidationRules.inputs = {
                    ...initialValidationRules.inputs,
                    ...this._VALIDATION_RULES[stepKey].inputs
                };
            }
        });
        return initialValidationRules;
    }

    /**
     * Initialize GCLID receiver
     */
    initializeGclidReceiver() {
        window.addEventListener('message', (event) => this.receiveGclidMessage(event), false);
    }

    /**
     * Receive GCLID value from the iframe and sets the hidden input
     * @param {*} event
     * @returns
     */
    receiveGclidMessage(event) {

        const allowedOrigins = [
            'https://landing.voices.systems',
            'https://www.voices.systems',
            'https://landing.voices.com',
            'https://www.voices.com'
        ];

        if (!allowedOrigins.includes(event.origin)) {
            return;
        }

        const gclid = event.data.gclid;
        const hiddenInput = document.querySelector('input[name="gclid"]');

        if (hiddenInput && gclid) {
            hiddenInput.value = gclid;
        }
    }


    addEventListeners() {

        // Handle back button click.
        this.backBtn.addEventListener('click', e => {
            e.preventDefault();
            if (this.currentStep > 1 && !this.isSocialSignup) {
                this.validator.removeValidationRules(Object.keys(this._VALIDATION_RULES[parseInt(this.currentStep)].inputs));
                this.currentStep--;
                this.renderStep(this.currentStep - 1);
                //helper text - step 1
                if (!window.location.pathname.includes('/signup/iframe')) {
                    this.continueBtn.removeAttribute('style');
                    document.getElementById('signup-title').nextElementSibling.innerHTML = '';
                    let helper_text = `<p class="text-center">Start by telling us about yourself.</p>`;
                    document.getElementById('signup-title').insertAdjacentHTML('afterend', helper_text);
                } else {
                    this.socialSignUpTitle.innerHTML = `<h2 id="signup-title">${this.signupTitle.innerHTML}</h2> `;
                    let helper_text = `<p class="text-center" id="signup-helper-text">` + this.currentHelperText.innerHTML + `</p>`;
                    document.getElementById('signup-title').insertAdjacentHTML('afterend', helper_text);
                    debounce(async () => await this.CheckSubmitButtonState(), 250)
                }
            }
        });

        //Handle toggling of Show password icon.
        if (this.showPassword) {
            this.showPassword.addEventListener('click', event => {
                if (this.passwordInput.type === 'password') {
                    this.showPassword.innerHTML = '<i class="fa fa-eye-slash"></i>';
                    this.showPassword.dataset.originalTitle = 'Hide Password';
                    this.passwordInput.type = 'text';
                    event.stopImmediatePropagation();
                } else {
                    this.passwordInput.type = 'password';
                    this.showPassword.innerHTML = '<i class="fa fa-eye"></i>';
                    this.showPassword.dataset.originalTitle = 'Show Password';
                    event.stopImmediatePropagation();
                }
            });
        }

        const _this = this;
        //handle signup now btn click event
        this.signupNowBtn.addEventListener('click', async e => {
            e.preventDefault();
            _this._buttonSend(this.signupNowBtn);
            const valid = await this.validator.validateInput(document.querySelector('input[name="account_type"]'));
            if (valid) {
                let selectedAccountType = document.querySelector('[name="account_type"]:checked').value;
                if (selectedAccountType == 'basic') {
                    this.createLead();
                }
                this.isSocialSignup = true;
                this.currentStep = 3;
                this.renderNonSocialStep();
                this.renderStep(this.currentStep);
                document.getElementById('member-non-social').classList.add('hidden');

                //validation for the next step
                if (selectedAccountType != 'basic') {
                    this.validator.addValidationRules({
                        policy: this._VALIDATION_RULES[parseInt(this.currentStep)].inputs.policy
                    });
                } else {
                    this.validator.addValidationRules(this._VALIDATION_RULES[parseInt(this.currentStep)].inputs);
                }
            }
            _this._buttonReturn(this.signupNowBtn);
        });

        //Handle continue btn click event
        this.continueBtn.addEventListener('click', async e => {
            e.preventDefault();
            _this._buttonSend(this.continueBtn);
            const valid = await this.validator.validateInput(document.getElementById('email'));

            if (valid) {
                const formData = new FormData(this.form);
                formData.append('token', this.securityFields.value);

                // Send Fetch Request
                await fetch(`/signup/check_is_email_valid`, {
                    method: 'POST',
                    body: formData
                })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error('Fetch Request Failed');
                        }
                        return response.json();
                    })
                    .then(async (json) => {
                        const {
                            status,
                            message,
                            signupdata,
                            token
                        } = json;
                        if (token && this.securityFields) {
                            this.securityFields.value = token;
                        }
                        if (status == 'error') {
                            this.isSocialSignup = false;
                            this.currentStep = 1;
                            this.validator.addValidationRules(this._VALIDATION_RULES[parseInt(this.currentStep)].inputs);
                            const emailInputDiv = document.getElementById('email');
                            const workEmailError = document.createElement('div');
                            workEmailError.className = 'form-input-message form-input-message-error';
                            workEmailError.id = 'email-input-error';
                            workEmailError.innerHTML = `<span>${message}</span>`;

                            if (emailInputDiv) {
                                const emailInputError = document.getElementById('email-input-error');
                                if (emailInputError) {
                                    emailInputError.remove();
                                }
                                emailInputDiv.insertAdjacentElement('afterend', workEmailError);
                                if (!!window.location.pathname.includes('/signup/iframe')) {
                                    const emailLoginText = document.getElementById('email-error-message');
                                    emailLoginText.remove();
                                }
                            }
                        } else {
                            let selectedAccountType = document.querySelector('[name="account_type"]:checked').value;
                            if (selectedAccountType == 'basic') {
                                this.createLead();
                            }
                            this.isSocialSignup = false;
                            this.currentStep = 3;
                            const iframeSignupData = JSON.parse(signupdata);
                            if (window.location.pathname.includes('/signup/iframe')) {
                                this.currentTitle = iframeSignupData.main_header;
                                this.signupHelperText = iframeSignupData.sub_header;
                            }
                            this.renderNonSocialStep();
                            this.renderStep(this.currentStep);
                            if (window.location.pathname.includes('/signup/iframe')) {
                                this.submitBtn.style.backgroundColor = this.signUpStep2Data['submit_disabled_cta_color'];
                            }
                            //validation for the next step
                            if (selectedAccountType != 'basic') {
                                this.validator.addValidationRules({
                                    ...this.passwordValidationWidget.VALIDATION_OBJECT,
                                    policy: this._VALIDATION_RULES[parseInt(this.currentStep)].inputs.policy
                                });
                            } else {
                                this.validator.addValidationRules(this._VALIDATION_RULES[parseInt(this.currentStep)].inputs);
                            }
                        }
                    });
            }
            _this._buttonReturn(this.continueBtn);
        });

        //Handle submit btn click event
        this.submitBtn.addEventListener('click', async e => {
            e.preventDefault();
            const valid = await this.validator.validateForm();
            if (valid) {
                this.selectedUserType = document.querySelector('[name="account_type"]:checked').value;
                await this.onSubmit();
            }
        });

        if(window.location.pathname.includes('/signup/iframe')){
            this.form.addEventListener('change',  debounce(async () => await this.CheckSubmitButtonState(), 250));
        }

        //Form submit events that variate based on sign up pathing
        this.form.addEventListener('submit', async e => {
            e.preventDefault();
        });

        this.initializeGoogleSSI();
    }

    CheckSubmitButtonState(){
        if(this.continueBtn.hasAttribute('disabled')){
            this.continueBtn.style.backgroundColor = this.signUpStep1Data['submit_disabled_cta_color'];
            this.submitBtn.style.backgroundColor = this.signUpStep2Data['submit_disabled_cta_color'];
        }else{
            this.continueBtn.style.backgroundColor = this.signUpStep1Data['submit_cta_color'];
            this.submitBtn.style.backgroundColor = this.signUpStep2Data['submit_cta_color'];
        }
    }

    //create leads
    async createLead() {

        const formData = new FormData(this.form);
        formData.append('token', this.securityFields.value);
        if (this.isSocialSignup) {
            formData.append('token', this.securityFields.value);
            formData.append('full_name', document.querySelector('.social-info-name').innerText);
            formData.append('email', document.querySelector('.social-info-email').innerText);
        }

        // Send Fetch Request
        await fetch(`/signup/create_lead`, {
            method: 'POST',
            body: formData
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Fetch Request Failed');
                }
                return response.json();
            })
            .then(async (json) => {
                const {
                    status,
                    message,
                    token
                } = json;
                if (token && this.securityFields) {
                    this.securityFields.value = token;
                }
                if (status == 'error') {
                    //handle error.
                    //console.log(message);
                }
            });
    }

    //Perform Social Signup steps
    async performSocialSignUpSteps(responsePayload) {

        //social signup email is taken
        const formData = new FormData(this.form);
        formData.append('email', responsePayload.email);
        // Send Fetch Request
        await fetch(`/signup/check_is_email_valid`, {
            method: 'POST',
            body: formData
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Fetch Request Failed');
                }

                return response.json();
            })
            .then(async (json) => {
                const {
                    status,
                    message,
                    token
                } = json;
                if (token && this.securityFields) {
                    this.securityFields.value = token;
                }
                if (status == 'error') {
                    this._SSOFailSteps();
                    const emailInputError = document.getElementById('email-input-error');
                    if (emailInputError) {
                        emailInputError.remove();
                    }
                    if (!!window.location.pathname.includes('/signup/iframe')) {
                        const emailLoginText = document.getElementById('email-error-message');
                        emailLoginText.remove();
                    }
                    if (message) {
                        this.displaySignupError(false, 'Unable to Connect Account', message);
                    }
                } else {
                    //Remove previous validations
                    this.currentStep = 1;
                    this.buildNewAndRemovePreviousRules();

                    this.isSocialSignup = true;

                    if (!!this.isSocialSignup) {
                        this.currentStep = 2;
                        this.renderStep(this.currentStep);
                        //unchecked account types on previous step
                        document.querySelector('[name="account_type"]').checked = false;

                        //Add SSO Validatons
                        this.addSSOStepValidations();
                    }

                    if (this.nonSocialStep) {
                        this.nonSocialStep.classList.add('hidden');
                    }

                    document.querySelector('input[name="is_social_signup"] ').value = 1;

                    if (responsePayload.provider === 'apple') {
                        document.querySelector('input[name="social_provider"]').value = 'apple';

                    } else if (responsePayload.provider === 'google') {
                        document.querySelector('input[name="social_provider"]').value = 'google';
                    }
                    this.isSocialSignup = true;
                    this.renderSocialStep(responsePayload);
                    this.currentStep = 2;
                    this.renderStep(this.currentStep);
                    this.validator.addValidationRules(this._VALIDATION_RULES[parseInt(this.currentStep)].inputs);
                }
            });

    }

    /**
     * Build Step validation obj
     */
    buildNewAndRemovePreviousRules() {

        //Build Step validation obj
        let stepValidationRules = { inputs: {} };
        Object.values(this._VALIDATION_RULES).forEach((stepRules) => {
            stepValidationRules.inputs = { ...stepValidationRules.inputs, ...stepRules.inputs };
        });

        //Remove validations stepValidationRules
        this.validator.removeValidationRules(Object.keys(this._VALIDATION_RULES[parseInt(this.currentStep)].inputs));
    }

    /**
     * Add Social step validation rules
     */
    addSSOStepValidations() {
        if (this.currentStep === 2) {
            this.validator.addValidationRules(this._VALIDATION_RULES[parseInt(this.currentStep)].inputs);
        }
    }

    /**
     *  Toggle Back Button
     */
    toggleBackButton() {
        if (this.currentStep > 2 && !this.isSocialSignup) {
            this.backBtn.classList.remove('hidden');
        } else {
            this.backBtn.classList.add('hidden');
        }
    }


    /**
     * Render Non-Social Step3
     */
    renderNonSocialStep() {
        if (this.socialSignUpTitle) {
            this.socialSignUpTitle.innerHTML = '';
            if (this.privateInvitee) {
                this.socialSignUpTitle.innerHTML = `<h2 id="signup-title">Sign Up to Invite ${this.privateInvitee}</h2>`;
                this.clientSelected.checked = true;
            } else {
                if (window.location.pathname.includes('/jobs/post_job')) {
                    this.socialSignUpTitle.innerHTML = `<h2 id="signup-title">Sign Up to Post Your Job</h2> `;
                } else {
                    this.socialSignUpTitle.innerHTML = `<h2 id="signup-title">${this.currentTitle}</h2> `;
                }
            }
        }
        //helper text - step 2
        if (!window.location.pathname.includes('/signup/iframe')) {
            let helper_text = `<p class="text-center">Take a moment to finish setting up your account.</p>`;
            document.getElementById('signup-title').insertAdjacentHTML('afterend', helper_text);
        } else {
            let helper_text = `<p class="text-center">` + this.signupHelperText + `</p>`;
            document.getElementById('signup-title').insertAdjacentHTML('afterend', helper_text);
        }

        this.nonSocialStep.classList.remove('hidden');
        let selectedAccountType = document.querySelector('[name="account_type"]:checked').value;
        if (selectedAccountType != 'basic') {
            this.nonSocialStep.classList.add('hidden');
        } else {
            this.nonSocialStep.classList.remove('hidden');
            if (this.showCompanyPhoneFields()) {
                this.nonSocialStep.innerHTML = '';
            }
        }
        document.getElementById('member-non-social').classList.remove('hidden');
        this.socialInfoContainer.classList.add('hidden');
        if (this.loginLink) {
            this.loginLink.classList.remove('hidden');
        }
    }

    /**
     *
     * Render Social Step Inputs
     * @param {*} responsePayload
     * @returns
     */
    renderSocialStep(responsePayload) {

        if (this.loginLink) {
            this.loginLink.classList.add('hidden');
        }
        this.nonSocialStep.classList.add('hidden');
        this.socialInfoContainer.classList.remove('hidden');

        if (this.socialSignUpTitle) {
            this.socialSignUpTitle.innerHTML = '';
            if (this.privateInvitee) {
                this.socialSignUpTitle.innerHTML = `<h2 id="signup-title">Sign Up to Invite ${this.privateInvitee} </h2>`;
                this.clientSelected.checked = true;
            } else {
                if (window.location.pathname.includes('/jobs/post_job')) {
                    this.socialSignUpTitle.innerHTML = `<h2 id="signup-title">Sign Up to Post Your Job</h2> `;
                } else {
                    this.socialSignUpTitle.innerHTML = `<h2 id="signup-title">${this.currentTitle}</h2> `;
                }
            }

            this.socialSignUpTitle.innerHTML += `<p class="text-center">Start by telling us about yourself.</p>`;
        }

        if (responsePayload != null) {
            if (responsePayload.picture && responsePayload.name && responsePayload.email) {

                this.socialInfoContainer.innerHTML = `
                <div class="social-info-container">
                    <div class="social-info-picture-container">
                        <img src="${responsePayload.picture}" alt="User Avatar" height="60px" width="60px" />
                    </div>
                    <div class="social-info-details-container">
                        <span class="social-info-name">${responsePayload.name}</span>
                        <span class="social-info-email">${responsePayload.email}</span>
                    </div>
                </div>
                `;
            } else if (!!responsePayload.name) {

                this.socialInfoContainer.innerHTML = `
                <div class="social-info-container d-flex justify-content-center">
                    <div class="text-center">
                        <span class="social-info-name">${responsePayload.name}</span>
                        <p class="social-info-email">${responsePayload.email}</p>
                    </div>
                </div>
                `;
            } else {
                this.socialInfoContainer.innerHTML = `
                <div class="social-info-container social-full-name-capture d-flex justify-content-center">
                        <div>
                            <span class="social-info-email">${responsePayload.email}</span>
                            <div class="form-group">
                                <label for="full_name">Full Name</label>
                                <input
                                        data-heap-member-type=""
                                        type="text"
                                        class="form-control form-control-success"
                                        id="full_name_social"
                                        name="full_name"
                                        required
                                />
                            </div>
                        </div>
                    </div>
                `;
            }
            document.getElementById('signUp-common-fields').remove();
            let socialAccountTyeContainer = this._signupCommonFields();
            this.socialInfoContainer.insertAdjacentHTML('afterend', socialAccountTyeContainer);
        }
        return '';
    }

    _signupCommonFields() {
        return `
            <div id="signUp-common-fields"> 
                <div id="signup-account-selection" class="form-group">
                    <label for="account-type">I want to</label>
                    <div id="account-type" class="radio-toggle-group">
                        <div class="radio-toggle">
                            <input type="radio" name="account_type" id="account_type-basic" value="basic"/>
                            <label for="account_type-basic"><span>Hire for a Project</span></label>
                        </div>
                        <div class="radio-toggle">
                            <input type="radio" name="account_type" id="account_type-guest" value="guest"/>
                            <label for="account_type-guest"><span>Find Work as a Talent</span></label>
                        </div>
                    </div>
                </div>
                <div id="news-checkbox-container" class="form-group">
                    <div class="d-flex">
                        <div class="checkbox checkbox-primary margin-right-small">
                            <input type="checkbox" id="news" name="news" title="By checking this box, you consent to receiving marketing news, offers, and promotional material from Voices." value="1"/>
                            <label for="news"></label>
                        </div>
                        <label for="news" class="text-sm marginTop">
                                By checking this box, you consent to receiving marketing news, offers, and promotional material from Voices.
                        </label>
                    </div>
                </div>
            </div>`;
    }

    renderStep(step = 1) {

        const stepDiv = document.querySelector(`.signup-step[data-step="${step}"]`);

        if (stepDiv) {
            document.querySelectorAll(`.signup-step`).forEach(step => step.classList.add('hidden'));
            document.querySelector(`.signup-step[data-step="${step}"]`).classList.remove('hidden');
            this.toggleBackButton();
        }
    }

    /**
     * Steps to be executed if an SSO fail occurs
     */
    _SSOFailSteps() {
        this.currentStep = 1;
        this.renderStep(this.currentStep);
        document.getElementById('signUp-common-fields').remove();
        let commonContainer = this._signupCommonFields();
        this.continueBtn.insertAdjacentHTML('beforebegin', commonContainer);
        this.isSocialSignup = false;
        this.validator.addValidationRules(this.initialValidationRules.inputs);
        if (this.loginLink) {
            this.loginLink.classList.remove('hidden');
        }
        if (this.socialSignUpTitle) {
            if (window.location.pathname.includes('/jobs/post_job')) {
                this.socialSignUpTitle.innerHTML = `<h2 id="signup-title">Sign Up to Post Your Job</h2> `;
            } else {
                this.socialSignUpTitle.innerHTML = `<h2 id="signup-title">${this.currentTitle}</h2> `;
            }

        }
        document.querySelector('input[name="is_social_signup"] ').value = '';
        document.querySelector('input[name="social_provider"]').value = '';
    }

    async onSubmit() {

        // Disable Submit button.
        this.validator.disableSubmitButton(this.submitBtn);

        const formData = new FormData(this.form);
        let signupEmail = formData.get('email');

        if (!!this.appleResponse) {
            this.responsePayload = this.appleResponse;
            signupEmail = this?.responsePayload?.email;

        } else if (!!this.googleResponse) {
            this.responsePayload = this.googleResponse;
            signupEmail = this?.responsePayload?.email;
        }

        if (!!this.isSocialSignup && !!this.responsePayload) {
            Object.entries(this.responsePayload).forEach(([key, value]) => {
                formData.append(key, value);
            });
        }

        formData.append('is_pre_job_signup', !!window.location.pathname.includes('/jobs/post_job'));
        formData.append('is_iframe_signup', !!window.location.pathname.includes('/signup/iframe'));
        formData.append('registration_source_url', window.location.pathname);
        if (this.signupModalOpenedByOrderPackageButton) {
            formData.append('registration_source_id', 6);
        }

        // Send Fetch Request
        await fetch(`/signup/ajax/${this.selectedUserType}`, {
            method: 'POST',
            body: formData
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Fetch Request Failed');
                }

                return response.json();
            })
            .then(async (json) => {
                const {
                    status,
                    message,
                    member_type,
                    token
                } = json;

                if (status === 'error') {
                    // Enable submit button
                    this.validator.enableSubmitButton({
                        submitBtn: this.submitBtn,
                        submitBtnText: 'Continue'
                    });
                    // Display API Errors Related to Email
                    if (message.email) {
                        if (this.isSocialSignup) {
                            this._SSOFailSteps();
                            // VWEB-4961:Password Security Imp.
                            this.displaySignupError(true, 'Unable to Connect Account', 'Email address is invalid. Please enter a different email address or <a id="error-banner-login-link" href="/login">log in</a>.');
                        } else {
                            this.currentStep = 1;
                            this.renderStep(this.currentStep);
                            const emailInput = this.form.querySelector('[name="email"]');
                            this.validator.generateErrorsForInput(
                                emailInput,
                                message.email || 'Something went wrong'
                            );
                        }
                    }
                    // Display SSO Errors
                    else if (message.oauth) {
                        this._SSOFailSteps();
                        this.displaySignupError(false, 'Unable to Connect Account', message.generic_error);

                        if (token && this.securityFields) {
                            this.securityFields.value = token;
                        }
                    } else {
                        this.displaySignupError(true, 'Error', message.generic_error);
                    }
                }

                if (status === 'success') {
                    this.bizableTracker(signupEmail);

                    if (await this.onSuccessfulSubmit('sign up', {
                        member_type: member_type,
                        redirect_url: message,
                        social_signup: this.isSocialSignup
                    })) {
                        window.location = message;
                    }
                }
            })
            .catch(err => {
                this.validator.enableSubmitButton({
                    submitBtn: this.submitBtn,
                    submitBtnText: 'Continue'
                });
                throw err;
            });
    }

    /**
     * @source https://experienceleague.adobe.com/docs/marketo-measure/using/marketo-measure-tracking/setting-up-tracking/adding-marketo-measure-script-to-different-form-providers/ajax-form-handling.html?lang=en#scenario-lead-information-collected-in-a-non-html-form
     *
     * Send tracking information to Bizible.
     *
     * @param email:string
     */
    bizableTracker(email) {
        if (!email) {
            return;
        }

        // Preamble for all API usage.
        window['Bizible'] = window['Bizible'] || {
            _queue: [], Push: function(o, p) {
                this._queue.push({ type: o, data: p });
            }
        };

        // Send email data to Bizible directly
        Bizible.Push('User', {
            eMail: email // required
        });
    }

    /**
     * Custom SignUp Error
     * @param {*} autoDismiss
     * @param {*} title
     * @param {*} errorMessage
     */
    displaySignupError(autoDismiss = true, title = 'Error', errorMessage = 'Something went wrong with your request. Please try again later.') {
        const timestamp = new Date().getTime();
        const signUpTitleDiv = document.getElementById('signup-title');
        const alert = document.createElement('div');
        alert.id = `alert-${timestamp}`;
        alert.role = 'alert';
        alert.className = 'alert alert-danger alert-dismissible';
        alert.innerHTML = `
            <button class="close" type="button" data-bs-dismiss="alert"><i class="far fa-times"></i><span class="sr-only">Close</span></button>
            <div aria-hidden="true" class="alert-icon-block sr-hidden">
                <i class="fas fa-exclamation-circle"></i>
            </div>
            <div class="alert-body-block">
                <div class="alert-body-title">
                <strong>${title}</strong>
                </div>
                <div class="alert-body-content">
                    <p>${errorMessage}</p>
                </div>
            </div>
        `;

        if (signUpTitleDiv) {
            signUpTitleDiv.insertAdjacentElement('afterend', alert);
            const alertLoginLink = document.querySelector(`#error-banner-login-link`);
            if (alertLoginLink) {
                alertLoginLink.addEventListener('click', function(e) {
                    e.preventDefault();
                    if (document.getElementById('login-instead')) {
                        document.getElementById('login-instead').click();
                    } else {
                        window.location = '/login';
                    }
                });
            }
        }

        // offsetwidth resets the element for the dom to make the transition work.
        // !important! Do not remove this without good reason.
        alert.offsetWidth;
        alert.classList.add('slide-in-transitioned');
        if (autoDismiss) {
            new Promise(function(resolve) {
                // Fade out the alert after delay time has passed
                setTimeout(function() {
                    alert.className += ' out'; // fade-in/out or slide-in/out
                    resolve();
                }, 8000);
            })
                // Then remove the element entirely after the css animation is complete
                .then(function() {
                    setTimeout(function() {
                        removeElementById(alert.id);
                    }, 8000);
                });
        }
        document.addEventListener('click', (e) => {
            const target = e.target.closest(`button[data-bs-dismiss="alert"]`);

            if (target) {
                alert.className += ' out';

                setTimeout(function() {
                    removeElementById(alert.id);
                }, 300);
            }
        });

    }

    _buttonSend(button) {
        if (window.__voicesButtonSend) {
            window.__voicesButtonSend(button);
        } else {
            buttonSend(button);
        }
    }

    _buttonReturn(button) {
        if (window.__voicesButtonReturn) {
            window.__voicesButtonReturn(button);
        } else {
            buttonReturn(button);
        }
    }
}

/**
 * Replacement function to use instead of remove() since IE11 does not support that function
 * @param id
 * @returns {HTMLElement}
 */
function removeElementById(id) {
    var elem = document.getElementById(id);
    if (elem && elem.parentNode) {
        elem.parentNode.removeChild(elem);
    }
}

function decodeJwtResponse(token) {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
}

module.exports = SignUpForm;
